import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";

const AllOrders = () => {
  const [orders, setOrders] = useState([]);
  const [openDelete, setOpenDelete] = useState(false);
  const [openPreview, setOpenPreview] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [orderDetails, setOrderDetails] = useState(null);

  useEffect(() => {
    fetchCompletedOrders();
  }, []);

  const fetchCompletedOrders = async () => {
    try {
      const response = await axios.get(`${server}/quote3D/orders/completedOrders`, {
        withCredentials: true,
      });
    //   console.log("Completed Orders Data:", response.data.completedOrders); // Log the data
      setOrders(response.data.completedOrders);
      console.log(response.data.completedOrders)
    } catch (error) {
      console.error("Error fetching completed orders:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${server}/quote3D/orders/deleteOrder/${id}`, {
        withCredentials: true,
      });
      toast.success("Order deleted successfully!");
      setOrders(orders.filter((order) => order.id !== id)); // Remove deleted order from state
      setOpenDelete(false); // Close the confirmation popup
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const handlePreview = (order) => {
    // console.log("Order Details for Preview:", order); // Log the order details
    setOrderDetails(order);
    setOpenPreview(true);
  };

  const columns = [
    { field: "order_id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    { field: "fullName", headerName: "Customer", minWidth: 150, flex: 0.7 },
    { field: "vendor_name", headerName: "Vendor", minWidth: 150, flex: 0.7 },
    { field: "orderName", headerName: "Order Name", minWidth: 130, flex: 0.7 },
    { field: "totalAmount", headerName: "Amount", minWidth: 130, flex: 0.7 },
    { field: "dateoforder", headerName: "Date of Order", minWidth: 130, flex: 0.8 },
    {
      field: "preview",
      headerName: "Preview Order",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handlePreview(params.row)}>
          <AiOutlineEye size={20} />
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete Order",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => setOrderId(params.id) || setOpenDelete(true)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  // Ensure orders is defined before mapping it to rows
  const rows = orders
    ? orders.map((order) => {
        // console.log("Order:", order); // Log each order to check its structure
        return {
          id: order.id,
          order_id: order.order_id,
          orderName: order.orderName,
          dateoforder: order.dateoforder,
          fullName: order.address ? order.address.fullName : 'N/A',
          vendor_name: order.prices && order.prices.length > 0 ? order.prices[0].vendor_name : 'N/A',
          totalAmount: order.prices && order.prices.length > 0 ? `INR ${order.prices[0].totalAmount}` : 'INR',
          email:order.address.email,
          address1:order.address.address.address1,
          address2:order.address.address.address2,
          city:order.address.city,
          state:order.address.state,
          items:order.quotation.length,
        };
      })
    : [];

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2 text-white ml-8">Completed Orders</h3>
        <div className="pl-8 pt-1">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            className="data-grid-text-white"
          />
        </div>
        {openDelete && (
          <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
              <div className="flex justify-end pt-2 pr-2">
                <RxCross1
                  size={25}
                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() => setOpenDelete(false)}
                />
              </div>
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                  Confirm Delete Order
                </h3>
                <div className="text-gray-700 mb-4 text-center">
                  Are you sure you want to delete this order?
                </div>
                <div className="flex justify-center space-x-4">
                  <Button
                    variant="contained"
                    className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-md focus:outline-none"
                    onClick={() => setOpenDelete(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md focus:outline-none"
                    onClick={() => handleDelete(orderId)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
        {openPreview && orderDetails && (
          <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-lg">
              {/* <div className="flex justify-end pt-2 pr-2">
                <RxCross1
                  size={25}
                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() => setOpenPreview(false)}
                />
              </div> */}
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                  Order Details
                </h3>
                <div className="text-gray-700 mb-4">
                  <p><strong>Order ID:</strong> {orderDetails.order_id}</p>
                  <p><strong>Order Name:</strong> {orderDetails.orderName}</p>
                  <p><strong>Customer:</strong> {orderDetails.fullName}</p>
                  <p><strong>Customer Email:</strong> {orderDetails.email}</p>
                  <p><strong>Customer Number:</strong> {orderDetails.phone}</p>
                  <p><strong>Address:</strong> {orderDetails.address1},{orderDetails.address2},{orderDetails.city},{orderDetails.state}</p>
                  <p><strong>Number of Models:</strong> {orderDetails.items}</p>
                  <p><strong>Vendor:</strong> {orderDetails.vendor_name}</p>
                  
                  <p><strong>Amount:</strong> {orderDetails.totalAmount}</p>
                  <p><strong>Date of Order:</strong> {orderDetails.dateoforder}</p>
                </div>
                <div className="flex justify-center space-x-4">
                  <Button
                    variant="contained"
                    className="bg-blue-500 hover:bg-blue-600 text-white px-6 py-2 rounded-md focus:outline-none"
                    onClick={() => setOpenPreview(false)}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllOrders;
