import React from 'react'
import Signup from "../components/Signup/Signup.jsx"
import Header from "../components/Layout/Header.jsx";
import Footer from "../components/Layout/Footer.jsx";
const SignupPage = () => {
  return (
    <div>
      <Header/>
      <Signup/>
      <Footer/>
    </div>
  )
}

export default SignupPage
