import React, { useState } from "react";
import logo from "../Images/logo.png";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
// import { MdOutlineAdminPanelSettings } from "react-icons/md";
import { AiOutlineLogin } from "react-icons/ai";
const Header = () => {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const firstName = user?.name.split(" ")[0];
  const navigate = useNavigate();
  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  // State to manage mobile menu visibility
  const [menuOpen, setMenuOpen] = useState(false);

  // Toggle function for the mobile menu
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  return (
    <nav className="sticky top-0 z-50 py-3 backdrop-blur-lg border-b bg-[#111827]">
      <div className="container px-4 mx-auto flex justify-between items-center">
        <div className="flex items-center">
          <Link to="/" className="flex items-center">
            <img className="h-10 w-10 mr-2" src={logo} alt="Logo" />
            <span className="text-2xl bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text">
              BharatMaker
            </span>
          </Link>
        </div>
        <div className="hidden lg:flex justify-center text-white">
          <Link to="/about" className="px-6 text-lg">
            About Us
          </Link>
          <Link to="/services" className="px-6 text-lg">
            Services
          </Link>
          <Link to="/contact" className="px-6 text-lg">
            Contact
          </Link>
          <Link to="/blogs" className="px-6 text-lg">
            Our Blogs
          </Link>
        </div>
        <div className="hidden lg:flex space-x-5 items-center">
          {isAuthenticated ? (
            <>
              <Link to="/profile" className="py-2 px-3 border rounded-md text-white">
                Hello, {firstName}
              </Link>
              {user && user.role === "Admin" && (
                <>
                <Link to="/admin/dashboard" className="inline-block px-5 py-2 ml-2 border rounded-md text-base font-medium text-white">
                  Admin Dashboard
                  {/* <div >
                  <div className="flex items-center cursor-pointer w-full mb-8">
                    <MdOutlineAdminPanelSettings size={20} />
                    <span className="pl-3 800px:block hidden">Admin</span>
                  </div>
                  </div> */}
                </Link>
                <button
                onClick={logoutHandler}
                // className="flex items-center py-2 px-4 bg-gradient-to-r from-red-500 to-red-800 text-white rounded-md transition-transform transform hover:scale-105"
              >
                <AiOutlineLogin size={25} className="mr-2" />
               
              </button>
                </>
              )}
            </>
          ) : (
            <Link to="/login" className="py-2 px-3 border rounded-md text-white">
              Sign In
            </Link>
          )}
          {!isAuthenticated && (
            <Link
              to="/shop-login"
              className="bg-gradient-to-r from-blue-500 to-blue-800 py-2 px-3 rounded-md text-white"
            >
              Become a Partner
            </Link>
          )}
        </div>
        <div className="lg:hidden">
          <button
            className="text-white focus:outline-none"
            onClick={toggleMenu}
            id="mobile-menu-button"
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={menuOpen ? "M6 18L18 6M6 6l12 12" : "M4 6h16M4 12h16m-7 6h7"}
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`lg:hidden transition-max-height duration-700 ease-in-out overflow-hidden ${
          menuOpen ? "max-h-screen" : "max-h-0"
        }`}
        id="mobile-menu"
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <Link to="/about" className="block px-5 py-2 ml-5 rounded-md text-base font-medium text-white">
            About Us
          </Link>
          <Link to="/services" className="block px-5 py-2 ml-5 rounded-md text-base font-medium text-white">
            Services
          </Link>
          <Link to="/contact" className="block px-5 py-2 ml-5 rounded-md text-base font-medium text-white">
            Contact
          </Link>
          <Link to="/blogs" className="block px-5 py-2 ml-5 rounded-md text-base font-medium text-white">
            Our Blogs
          </Link>
          {isAuthenticated ? (
            <>
              <Link to="/profile" className="inline-block px-8 py-2 ml-5 border rounded-md text-base font-medium text-white">
                Hello, {firstName}
              </Link>
              {user && user.role === "Admin" && (
                <Link to="/admin/dashboard" className="inline-block px-5 py-2 ml-5 border rounded-md text-base font-medium text-white">
                  Admin Dashboard
                  {/* <div >
                  <div className="flex items-center cursor-pointer w-full mb-8">
                    <MdOutlineAdminPanelSettings size={20} />
                    <span className="pl-3 800px:block hidden">Admin</span>
                  </div>
                  </div> */}
                </Link>
                
              )}
            </>
          ) : (
            <Link to="/login" className="inline-block px-5 py-2 my-5 border rounded-md text-base font-medium text-white ml-2 mr-5">
              Sign In
            </Link>
          )}
          {!isAuthenticated && (
            <Link
              to="/shop-login"
              className="inline-block bg-gradient-to-r from-blue-500 to-blue-800 py-2 px-3 rounded-md text-base font-medium text-white"
            >
              Become a Partner
            </Link>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Header;
