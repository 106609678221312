import React, { useEffect } from 'react'
import QuotationAskDetail from "../components/Quotation/QuotationAskDetail.jsx";
import StlViewers from '../components/AutoQuote/StlViewer.jsx';
import { useParams } from 'react-router-dom';
import Header from '../components/Layout/Header.jsx';
import Loader from '../components/Layout/Loader.jsx';
import { useSelector } from 'react-redux';
const StlViewerPage = () => {
    // const {fileUrl} = useParams()

    // useEffect(() => {
    //     console.log(fileUrl)
    // })

  const { loading } = useSelector((state) => state.user);

  return (
    <div>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <>
      
      <div>
        <StlViewers />
      </div>
      </>
      )}
    </div>
  )
}

export default StlViewerPage
