import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import styles from "../../styles/styles";
import { server } from "../../server";

const ShopInfo = ({ isOwner }) => {
  const [data, setData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    axios.get(`${server}/shop/get-shop-info/${id}`)
      .then((res) => {
        setData(res.data.shop);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const servicesArray = data.services || [];
  const servicesString = servicesArray.join(", ");

  const logoutHandler = async () => {
    await axios.get(`${server}/shop/logout`, { withCredentials: true });
    window.location.reload();
  };

  return (
    <div>
      <div className="w-full py-5">
        <h3 className="text-center py-2 text-[20px] font-bold">{data.name}</h3>
        <p className="text-[16px] text-white p-[10px] flex items-center">
          {data.description}
        </p>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Address</h5>
        <h4 className="text-white">{data.address}, {data.city}, {data.state}, {data.zipCode}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Email</h5>
        <h4 className="text-white">{data.email}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Phone Number</h5>
        <h4 className="text-white">{data.phoneNumber}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">GST Number</h5>
        <h4 className="text-white">{data.gstNumber}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Services Offered</h5>
        <h4 className="text-white">{servicesString}</h4>
      </div>
      <div className="p-3">
        <h5 className="font-[600]">Joined On</h5>
        <h4 className="text-white">{data?.createdAt?.slice(0, 10)}</h4>
      </div>
      {isOwner && (
        <div className="py-3 px-4">
          <Link to="/settings">
            <div className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`}>
              <span className="text-white">Edit Shop</span>
            </div>
          </Link>
          <div className={`${styles.button} !w-full !h-[42px] !rounded-[5px]`} onClick={logoutHandler}>
            <span className="text-white">Log Out</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShopInfo;
