import React from "react";
import { AiFillInstagram, AiFillLinkedin, AiFillYoutube } from "react-icons/ai";
import logo from "../Images/logo.png";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div className="bg-[#0f1420] text-white">
      <div className="grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-6 sm:px-8 px-5 py-16 sm:text-center">
        <ul className="text-center sm:text-left border-b-2 border-gray-600 sm:border-none pb-6 sm:pb-0">
          <div className="flex items-center flex-shrink-0 justify-center sm:justify-start">
            <img className="h-10 w-10 mr-2" src={logo} alt="Logo" />
            <span className="text-2xl bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text">
              BharatMaker
            </span>
          </div>
          {/* <p className="mt-2">Crafted By India</p> */}
          <div className="flex justify-center items-center mt-3 sm:justify-start">
            <a
              href="https://www.linkedin.com/company/encrypt-lab"
              target="_blank"
              rel="noopener noreferrer"
              className="cursor-pointer mx-1"
            >
              <AiFillLinkedin size={25} />
            </a>
            <AiFillInstagram size={25} className="cursor-pointer mx-1" />
            <AiFillYoutube size={25} className="cursor-pointer mx-1" />
          </div>
        </ul>

        <ul className="text-center sm:text-left border-b-2 border-gray-600 sm:border-none pb-6 sm:pb-0">
          <h1 className="mb-3 font-semibold">Company</h1>
          <Link to="/about">
            <li className="mb-2">About Us</li>
          </Link>
          <Link to="/blogs">
            <li className="mb-2">Our Blog</li>
          </Link>
          <Link to="/services">
            <li className="mb-2">Services</li>
          </Link>
        </ul>

        <ul className="text-center sm:text-left border-b-2 border-gray-600 sm:border-none pb-6 sm:pb-0">
          <h1 className="mb-3 font-semibold">Support</h1>
          <li className="mb-2">FAQ</li>
          <li className="mb-2">Reviews</li>
          <Link to="/contact">
            <li className="mb-2">Contact Us</li>
          </Link>
        </ul>

        <ul className="text-center sm:text-left border-b-2 border-gray-600 sm:border-none pb-6 sm:pb-0">
          <h1 className="mb-3 font-semibold">Services</h1>
          <li className="mb-2">3D-Printing</li>
          <li className="mb-2">Laser Cutting</li>
          <li className="mb-2">Many Coming Soon...</li>
        </ul>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 text-center pt-2 text-gray-400 text-sm pb-8">
        <div className="col-span-1 sm:col-span-2 lg:col-span-1"></div>
        <div className="col-span-1 sm:col-span-1 lg:col-span-1">
          <span>© 2024 BharatMaker. All rights reserved.</span>
        </div>
      </div>
    </div>
  );
};

export default Footer;
