import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { AiOutlineDelete, AiOutlineEye } from "react-icons/ai";

import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { server } from "../../server";

const AllSellers = () => {
  const [sellers, setSellers] = useState([]);
  const [open, setOpen] = useState(false);
  const [userId, setUserId] = useState("");

  useEffect(() => {
    getAllSellers();
  }, []);

  const getAllSellers = async () => {
    try {
      const response = await axios.get(`${server}/shop/admin-all-sellers`, {
        withCredentials: true,
      });
      setSellers(response.data.sellers);
    } catch (error) {
      console.error("Error fetching sellers:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${server}/shop/delete-seller/${id}`, {
        withCredentials: true,
      });
      toast.success("Seller deleted successfully!");
      getAllSellers(); // Refresh sellers after deletion
      setOpen(false); // Close the confirmation modal after deletion
    } catch (error) {
      console.error("Error deleting seller:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 130, flex: 0.7 },
    { field: "email", headerName: "Email", minWidth: 130, flex: 0.7 },
    {
      field: "address",
      headerName: "Seller Address",
      minWidth: 130,
      flex: 0.7,
    },
    { field: "joinedAt", headerName: "Joined At", minWidth: 130, flex: 0.8 },
    {
      field: "preview",
      headerName: "Preview Shop",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/shop/preview/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
    {
      field: "delete",
      headerName: "Delete Seller",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => setUserId(params.id) || setOpen(true)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  const rows = sellers.map((item) => ({
    id: item.id,
    name: item?.name,
    email: item?.email,
    joinedAt: item.createdAt.slice(0, 10),
    address: item.address,
  }));

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2 text-white ml-8">
          All Vendors
        </h3>
        <div className="pl-8 pt-1">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            className="data-grid-text-white"
          />
          {open && (
            <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-end pt-2 pr-2">
                  <RxCross1
                    size={25}
                    className="text-gray-500 hover:text-gray-700 cursor-pointer"
                    onClick={() => setOpen(false)}
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                    Confirm Delete Seller
                  </h3>
                  <div className="text-gray-700 mb-4 text-center">
                    Are you sure you want to delete this seller?
                  </div>
                  <div className="flex justify-center space-x-4">
                    <Button
                      variant="contained"
                      className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-md focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md focus:outline-none"
                      onClick={() => handleDelete(userId)}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AllSellers;
