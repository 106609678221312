import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import {
  // backend_url,
  server,
} from "../../server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import ReviewForm from "./ReviewForm";
// import { Link } from "react-router-dom";
Modal.setAppElement("#root"); // Set root element for accessibility

const QuotationAskDetail = () => {
  const { order_id } = useParams();
  const [order, setOrder] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
   const [loading, setLoading] = useState(false);
  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${server}/quote3D/order/${order_id}`);
        setOrder(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, [order_id]);

  const handleSelectVendor = async () => {
    setLoading(true); 
    try {
      await axios.post(`${server}/quote3D/order/${order_id}/selectVendor`, {
        modelPrices: selectedPrice.modelPrices,
        vendor_name: selectedPrice.vendor_name,
        vendor_id: selectedPrice.vendor_id,
        deliveryCharge: selectedPrice.deliveryCharge,
        description: selectedPrice.description,
        gstAmount: selectedPrice.gstAmount,
        totalAmount: selectedPrice.totalAmount,
        deliveryDate: selectedPrice.deliveryDate,
      });

      // Refresh order details to reflect the changes
      const response = await axios.get(`${server}/quote3D/order/${order_id}`);
      setOrder(response.data);
      toast.success("Vendor selected successfully!");
      setIsModalOpen(false);
      setIsModalOpen(false);
    } catch (error) {
      setIsModalOpen(false);
      console.error("Error selecting vendor price:", error);
      toast.error("Failed to select vendor!");
    }
  };

  const openModal = (price) => {
    setSelectedPrice(price);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedPrice(null);
  };

  if (!order) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="relative w-12 h-12">
          <div className="h-12 w-12 border-t-4 border-b-4 border-gray-800 rounded-full animate-spin"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 rounded-full bg-gray-800 animate-ping"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg mt-10">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Order Details</h2>

      <div className="mb-3">
        <h4 className="text-lg font-semibold text-gray-800">Order ID:</h4>
        <p className="text-gray-600">{order.order_id}</p>
      </div>
      <div className="mb-3">
        <h4 className="text-lg font-semibold text-gray-800">Order Name:</h4>
        <p className="text-gray-600">{order.orderName}</p>
      </div>

      <div className="mb-6">
        <h4 className="text-lg font-semibold text-gray-800">Address:</h4>
        <p className="text-gray-600">{`${order.address.fullName}, ${
          order.address.address.address1
        }, ${order.address.address.address2 || ""}, ${order.address.city}, ${
          order.address.state
        }, ${order.address.zipCode}, ${order.address.country}`}</p>
        <p className="text-gray-600">Email: {order.address.email}</p>
        <p className="text-gray-600">Phone: {order.address.phone || "N/A"}</p>
      </div>

      <div className="mb-6">
        <h4 className="text-lg font-semibold text-gray-900">Items:</h4>
        {order.quotation.map((item, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-200 rounded-lg">
            <p className="text-gray-700">
              <strong>Model Name:</strong> {item.modelname}
            </p>
            <div className="my-2">
              <p className="text-gray-700">
                <strong>Uploaded Files:</strong>
              </p>
              {item.files && item.files.length > 0 ? (
                item.files.map((filePath, fileIndex) => {
                  // Extract the base filename without extension
                  const fileName = filePath.split("/").pop().split("-")[0];

                  return (
                    <div
                      key={fileIndex}
                      className="mt-2 flex items-center bg-gray-100 p-3 rounded-lg"
                    >
                      <FontAwesomeIcon
                        icon={faFile}
                        className="text-gray-500 mr-2"
                      />
                      <p className="text-black mr-2 truncate">{fileName}</p>
                      <a href={filePath} download>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="text-gray-500"
                        />
                      </a>
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-600">No files uploaded</p>
              )}
            </div>
            <p className="text-gray-700">
              <strong>Material:</strong> {item.material}
            </p>
            <p className="text-gray-700">
              <strong>Color:</strong> {item.color}
            </p>
            <p className="text-gray-700">
              <strong>Quantity:</strong> {item.quantity}
            </p>
            <p className="text-gray-700">
              <strong>Tolerance:</strong> {item.tolerance || "N/A"}
            </p>
            <p className="text-gray-700">
              <strong>Description:</strong> {item.description || "N/A"}
            </p>
            <p className="text-gray-700">
              <strong>Required Date:</strong>{" "}
              {new Date(item.requiredDate).toLocaleDateString()}
            </p>
            <p className="text-gray-700">
              <strong>Fill Density:</strong> {item.fillDensity}
            </p>
          </div>
        ))}
      </div>
      {/* <div className="mb-8">
        <h4 className="text-lg font-semibold text-gray-900">Uploaded Files:</h4>
        {order.filePath.length > 0 ? (
          order.filePath.map((filePath, index) => (
            <div
              key={index}
              className="mt-2 flex items-center bg-gray-100 p-3 rounded-lg"
            >
              <FontAwesomeIcon icon={faFile} className="text-gray-500 mr-2" />
              <p className="text-black mr-2 truncate">
                {filePath.split("\\").pop().split("-")[0]}.
                {filePath.split(".").pop()}
              </p>
              <Link to={`${backend_url}${filePath}`} download>
                <FontAwesomeIcon icon={faDownload} className="text-gray-500" />
              </Link>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No files uploaded</p>
        )}
      </div> */}

<div className="mb-6">
        <h4 className="text-lg font-semibold text-gray-900">Vendor Prices:</h4>
        {order.prices.length > 0 ? (
          order.prices.map((price, index) => (
            <div key={index} className="mb-4 p-4 bg-gray-200 rounded-lg">
              <div className="flex justify-between items-center">
              <p className="text-gray-700">
                <strong>Vendor Name:</strong> {price.vendor_name}
              </p>
              {price.vendor_profile? (
                <div className="bg-gray-900 text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-300">
                <Link to={'https://bharatmaker.com/shop/preview/' + price.vendor_profile} className="text-white">
                View Vendor Profile
                </Link>
              </div>
              ) : (
                <div></div>
              )}
              </div>
              <p className="text-gray-700">
                <strong>Delivery Date:</strong>{" "}
                {new Date(price.deliveryDate).toLocaleDateString()}
              </p>
              <div>
                {price.modelPrices.map((modelPrice, idx) => (
                  <div key={idx}>
                    <p className="text-gray-700">
                      <strong>Model Name:</strong> {modelPrice.modelname}
                    </p>
                    <p className="text-gray-700">
                      <strong>Price:</strong> INR {modelPrice.price.toFixed(2)}
                    </p>
                  </div>
                ))}
              </div>
              <p className="text-gray-700">
                <strong>Delivery Charge:</strong> INR{" "}
                {price.deliveryCharge.toFixed(2)}
              </p>
              <p className="text-gray-700">
                <strong>Description:</strong> {price.description}
              </p>
              <p className="text-gray-700">
                <strong>
                  GST Amount <span className="text-gray-500">(18%)</span>:
                </strong>{" "}
                INR {price.gstAmount.toFixed(2)}
              </p>
              <p className="text-gray-700">
                <strong>Total Amount:</strong> INR {price.totalAmount.toFixed(2)}
              </p>
              {order.orderStatus === "Quote" && (
                <button
                  onClick={() => openModal(price)}
                  className="mt-2 bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                >
                  Select this vendor
                </button>
              )}
            </div>
          ))
        ) : (
          <p className="text-gray-600">No vendor prices available</p>
        )}
      </div>
      {order.orderStatus === "Completed" && (
        <div className="mt-6">
          <ReviewForm order_id={order_id} />
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Vendor Selection"
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            padding: "2rem",
            borderRadius: "8px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          },
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
        }}
      >
        <h2 className="text-2xl font-bold mb-4 text-gray-800">
          Confirm Vendor Selection
        </h2>
        {selectedPrice && (
          <div>
            <p className="text-gray-700">
              <strong>Vendor Name:</strong> {selectedPrice.vendor_name}
            </p>
            <p className="text-gray-700">
              <strong>Delivery Date:</strong>{" "}
              {new Date(selectedPrice.deliveryDate).toLocaleDateString()}
            </p>
            {/* <p className="text-gray-700">
              <strong>Price:</strong> INR {selectedPrice.price.toFixed(2)}
            </p> */}
            <div>
                {selectedPrice.modelPrices.map((modelPrice, idx) => (
                  <div key={idx}>
                    <p className="text-gray-700">
                      <strong>Model Name:</strong> {modelPrice.modelname}
                    </p>
                    <p className="text-gray-700">
                      <strong>Price:</strong> INR {modelPrice.price.toFixed(2)}
                    </p>
                  </div>
                ))}
              </div>
            <p className="text-gray-700">
              <strong>Delivery Charge:</strong> INR{" "}
              {selectedPrice.deliveryCharge.toFixed(2)}
            </p>
            <p className="text-gray-700">
              <strong>Description:</strong> {selectedPrice.description}
            </p>
            <p className="text-gray-700">
              <strong>
                GST Amount <span className="text-gray-500">(18%)</span>:
              </strong>{" "}
              INR {selectedPrice.gstAmount.toFixed(2)}
            </p>
            <p className="text-gray-700">
              <strong>Total Amount:</strong> INR{" "}
              {selectedPrice.totalAmount.toFixed(2)}
            </p>
          </div>
        )}
        <div className="mt-6 flex justify-end">
          <button
            onClick={closeModal}
            className="bg-gray-400 text-white py-2 px-4 rounded hover:bg-gray-500 mr-2"
          >
            Cancel
          </button>
          <button
                  onClick={handleSelectVendor}
                  className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                    <div className="flex items-center">
                      <div className="w-4 h-4 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                      Confirming...
                    </div>
                  ) : (
                    "Confirm"
                  )}
                </button>
        </div>
      </Modal>
    </div>
  );
};

export default QuotationAskDetail;
