import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import Modal from "react-modal";

Modal.setAppElement("#root"); // Set root element for accessibility

const QuotationLaser = () => {
  const initialFormState = {
    Model: [],
    material: "",
    color: "",
    quantity: "",
    tolerance: "",
    thickness: "",
    description: "",
    requiredDate: "",
  };
  
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [forms, setForms] = useState([{ ...initialFormState }]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (index, e) => {
    const { name, value, files } = e.target;
    const newForms = [...forms];
    if (files) {
      newForms[index][name] = Array.from(files);
    } else {
      newForms[index][name] = value;
    }
    setForms(newForms);
  };

  const handleAddForm = () => {
    setForms([...forms, { ...initialFormState }]);
  };

  const handleRemoveForm = (index) => {
    const newForms = forms.filter((_, formIndex) => formIndex !== index);
    setForms(newForms);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    forms.forEach(async (form) => {
      const formData = new FormData();
      form.Model.forEach((file) => formData.append("Model", file));
      formData.append("material", form.material);
      formData.append("color", form.color);
      formData.append("quantity", parseInt(form.quantity, 10));
      formData.append("tolerance", form.tolerance);
      formData.append("thickness", form.thickness);
      formData.append("description", form.description);
      formData.append("requiredDate", form.requiredDate);

      // Replace with your form submission logic
      console.log([...formData.entries()]);
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    navigate("/");
  };

  return (
    <div>
      <Header />
      <div className="min-h-screen flex flex-col bg-gray-50">
        <div className="bg-[#111827] py-12 sm:px-6 lg:px-8">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 className="text-center text-3xl font-extrabold text-white">
              Quotation - Laser Cutting
            </h2>
          </div>
          {forms.map((form, index) => (
            <div key={index} className="mt-4 sm:mx-auto sm:w-full sm:max-w-[30rem]">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <form className="space-y-6">
                  <div>
                    <label htmlFor={`modelFiles-${index}`} className="block text-sm font-medium text-black">
                      Model(s) in DXF Format <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="file"
                      name="Model"
                      id={`modelFiles-${index}`}
                      accept=".dxf"
                      multiple
                      required
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                    />
                  </div>
                  <div>
                    <label htmlFor={`material-${index}`} className="block text-sm font-medium text-black">
                      Material <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="material"
                      id={`material-${index}`}
                      autoComplete="off"
                      required
                      value={form.material}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                    />
                  </div>
                  <div>
                    <label htmlFor={`color-${index}`} className="block text-sm font-medium text-black">
                      Color <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="color"
                      id={`color-${index}`}
                      autoComplete="off"
                      required
                      value={form.color}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                    />
                  </div>
                  <div>
                    <label htmlFor={`quantity-${index}`} className="block text-sm font-medium text-black">
                      Quantity <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="number"
                      name="quantity"
                      id={`quantity-${index}`}
                      autoComplete="off"
                      required
                      value={form.quantity}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                    />
                  </div>
                  <div>
                    <label htmlFor={`tolerance-${index}`} className="block text-sm font-medium text-black">
                      Tolerance
                    </label>
                    <input
                      type="text"
                      name="tolerance"
                      id={`tolerance-${index}`}
                      autoComplete="off"
                      placeholder="1 mm"
                      value={form.tolerance}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black placeholder-gray-500"
                    />
                  </div>
                  <div>
                    <label htmlFor={`thickness-${index}`} className="block text-sm font-medium text-black">
                      Thickness of Material <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="text"
                      name="thickness"
                      id={`thickness-${index}`}
                      autoComplete="off"
                      required
                      value={form.thickness}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                    />
                  </div>
                  <div>
                    <label htmlFor={`description-${index}`} className="block text-sm font-medium text-black">
                      Description <span className="text-gray-500">(if needed)</span>
                    </label>
                    <textarea
                      name="description"
                      id={`description-${index}`}
                      autoComplete="off"
                      value={form.description}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      rows="4"
                    />
                  </div>
                  <div>
                    <label htmlFor={`requiredDate-${index}`} className="block text-sm font-medium text-black">
                      Required Date <span className="text-red-500">*</span>
                    </label>
                    <input
                      type="date"
                      name="requiredDate"
                      id={`requiredDate-${index}`}
                      autoComplete="off"
                      required
                      value={form.requiredDate}
                      onChange={(e) => handleChange(index, e)}
                      className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                    />
                  </div>
                  {forms.length > 1 && (
                    <div>
                      <button
                        type="button"
                        onClick={() => handleRemoveForm(index)}
                        className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-500"
                      >
                        Remove Form
                      </button>
                    </div>
                  )}
                </form>
              </div>
            </div>
          ))}
          <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
            <button
              onClick={handleAddForm}
              className="group relative w-full h-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500"
              >
                Add Another Form
              </button>
            </div>
            <div className="sm:mx-auto sm:w-full sm:max-w-md mt-4">
              <button
                onClick={handleSubmit}
                className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-800 hover:bg-blue-700"
              >
                Ask for Quote(s)
              </button>
            </div>
          </div>
        </div>
        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          contentLabel="Service Coming Soon"
          style={{
            content: {
              top: '50%',
              left: '50%',
              right: 'auto',
              bottom: 'auto',
              marginRight: '-50%',
              transform: 'translate(-50%, -50%)',
              padding: '2rem',
              borderRadius: '8px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            },
            overlay: {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          <h2 className="text-2xl font-bold mb-4 text-gray-800">Service Coming Soon</h2>
          <p className="text-gray-700 mb-4">This service will be available soon. Thank you for your patience.</p>
          <div className="flex justify-end">
            <button onClick={handleCloseModal} className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">OK</button>
          </div>
        </Modal>
      </div>
    );
  };
  
  export default QuotationLaser;
  
