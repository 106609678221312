import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Header from "../Layout/Header";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { Country, State, City } from "country-state-city";
import styles from "../../styles/styles"; // Adjust path as per your file structure
// import ShippingInfo from "../Checkout/Checkout"; // Adjust import path as per your file structure
import Footer from "../Layout/Footer";

const Quotation3D = () => {
  const initialFormState = {
    modelname: "",
    material: "",
    color: "",
    quantity: "",
    tolerance: "",
    description: "",
    requiredDate: "",
    fillDensity: "",
    files: [],
  };

  const { user } = useSelector((state) => state.user);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [orderName, setOrderName] = useState("");
  const [forms, setForms] = useState([{ ...initialFormState }]);
  const [loading, setLoading] = useState(false);
  // const [
  //   files
  //   , setFiles] = useState([]);
  const [step, setStep] = useState(1);
  const navigate = useNavigate();
  const [userInfo, setUserInfo] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOrderNameChange = (e) => {
    setOrderName(e.target.value);
  };

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newForms = [...forms];
    newForms[index][name] = value;
    setForms(newForms);
  };

  const handleAddForm = () => {
    setForms([...forms, { ...initialFormState }]);
  };

  const handleRemoveForm = (index) => {
    const newForms = forms.filter((_, formIndex) => formIndex !== index);
    setForms(newForms);
  };

  const handleFileChange = (index, e) => {
    const newForms = [...forms];
    const filesArray = Array.from(e.target.files);
    newForms[index].files = filesArray; // Store File objects instead of file names
    setForms(newForms);
  };

  const validateStep1 = () => {
    if (!orderName) {
      toast.error("Please provide an order name.");
      return false;
    }
    for (const form of forms) {
      if (
        !form.modelname ||
        !form.material ||
        !form.color ||
        !form.quantity ||
        // !form.fillDensity ||
        !form.requiredDate ||
        form.files.length === 0
      ) {
        toast.error("Please fill out all required fields in the form.");
        return false;
      }
    }
    return true;
  };

  const validateStep2 = () => {
    if (!address1 || !address2 || !zipCode || !country || !state || !city) {
      toast.error("Please fill out all required fields for delivery address.");
      return false;
    }
    // console.log(address1,address2,zipCode,country);
    return true;
  };
  const handleNextStep = () => {
    if (step === 1 && validateStep1()) {
      setStep(step + 1);
    }
    if (step === 2 && validateStep2()) {
      setStep(step + 1);
    }
  };

  const handlePreviousStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formData = new FormData();
      forms.forEach((form, index) => {
        form.files.forEach((file) => {
          formData.append(`files-${index}`, file); // Include index to identify files by form
        });
      });

      const quotationData = { orderName, forms };
      const checkoutData = {
        fullName: user && user.name,
        email: user && user.email,
        phone: user && user.phoneNumber,
        address: { address1, address2 },
        zipCode,
        country,
        state,
        city,
      };
      const user_id = user && user.id.toString();
      const payload = { quotationData, checkoutData, user_id };
      formData.append("payload", JSON.stringify(payload));

      await axios.post(`${server}/quote3D/order`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      setLoading(false);
      toast.success("Files uploaded successfully!");
      toast.success("Order Successfully Placed!");
      setForms(forms.map((form) => ({ ...form, files: [] }))); // Clear files after successful upload
      navigate("/req-received");
    } catch (error) {
      setLoading(false);
      console.error("Error uploading files:", error);
      toast.error("Error uploading files: " + error.message);
    }
  };

  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div>
            <div className="mt-0 sm:mx-auto sm:w-full sm:max-w-[30rem]">
              <div className="bg-gray-300 py-4 px-4  sm:rounded-lg sm:px-10 shadow-md rounded-lg">
                <div>
                  <label
                    htmlFor="orderName"
                    className="block text-sm font-medium text-black"
                  >
                    Order Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="orderName"
                    id="orderName"
                    autoComplete="off"
                    required
                    value={orderName}
                    onChange={handleOrderNameChange}
                    className="appearance-none block w-full px-3 py-2 border border-gray-500 rounded-md shadow-sm focus:outline-none focus:ring-black focus:border-black sm:text-sm bg-gray-300 text-black"
                  />
                </div>
              </div>
            </div>
            {forms.map((form, index) => (
              <div
                key={index} // Added key prop with unique value
                className="mt-4 sm:mx-auto sm:w-full sm:max-w-[30rem]"
              >
                <div className="bg-white py-8 px-4 shadow-md rounded-lg sm:rounded-lg sm:px-10">
                  <form className="space-y-6">
                    <div>
                      <label
                        htmlFor={`modelname-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Model Name <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="modelname"
                        id={`modelname-${index}`}
                        autoComplete="off"
                        required
                        value={form.modelname}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`files-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Upload Files <span className="text-red-500">*</span> <span className="text-gray-500">(.stl & .amf files only)</span>
                      </label>
                      <input
                        type="file"
                        name={`files-${index}`}
                        id={`files-${index}`}
                        accept=".stl,.amf"
                        multiple
                        required
                        onChange={(e) => handleFileChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`material-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Material <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="material"
                        id={`material-${index}`}
                        autoComplete="off"
                        required
                        value={form.material}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`color-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Color <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        name="color"
                        id={`color-${index}`}
                        autoComplete="off"
                        required
                        value={form.color}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`quantity-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Quantity <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="number"
                        name="quantity"
                        id={`quantity-${index}`}
                        autoComplete="off"
                        required
                        value={form.quantity}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`tolerance-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Tolerance
                      </label>
                      <input
                        type="text"
                        name="tolerance"
                        id={`tolerance-${index}`}
                        autoComplete="off"
                        placeholder="1 mm"
                        value={form.tolerance}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black placeholder-gray-500"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`fillDensity-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Fill Density {" "}
                        <span className="text-gray-500">
                          (15%-Light, 50%-Strong, 80%-Very Strong)
                        </span>
                      </label>
                      <input
                        type="text"
                        name="fillDensity"
                        id={`fillDensity-${index}`}
                        autoComplete="off"
                        // required
                        value={form.fillDensity}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`description-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Description{" "}
                        <span className="text-gray-500">(if needed)</span>
                      </label>
                      <textarea
                        name="description"
                        id={`description-${index}`}
                        autoComplete="off"
                        value={form.description}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                        rows="4"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor={`requiredDate-${index}`}
                        className="block text-sm font-medium text-black"
                      >
                        Required Date <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="date"
                        name="requiredDate"
                        id={`requiredDate-${index}`}
                        required
                        value={form.requiredDate}
                        onChange={(e) => handleChange(index, e)}
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-black"
                      />
                    </div>
                    <div className="flex justify-end">
                      {forms.length > 1 && (
                        <button
                          type="button"
                          onClick={() => handleRemoveForm(index)}
                          className="text-sm text-red-500 hover:text-red-700 focus:outline-none focus:text-red-700"
                        >
                          Remove
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            ))}
            <div className="mt-4 flex justify-center">
              <button
                type="button"
                onClick={handleAddForm}
                className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-black bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                Add Another Model
              </button>
            </div>
          </div>
        );

      case 2:
        return (
          <div>
            {/* <div className="mt-4 sm:mx-auto sm:w-full sm:max-w-[30rem]"> */}
            {/* <div className="bg-gray-300 py-4 px-4 shadow sm:rounded-lg sm:px-10"> */}
            <div className="w-full flex flex-col items-center py-0">
              <div className="w-full 800px:w-[65%]">
                <div className="w-full 800px:w-[95%] bg-black rounded-md p-5 pb-8">
                  <h5 className="text-[18px] font-[500]">Shipping Address</h5>
                  <br />
                  <form>
                    <div className="w-full flex pb-3">
                      <div className="w-[50%] pr-2">
                        <label className="block pb-2">Full Name</label>
                        <input
                          type="text"
                          value={user && user.name}
                          required
                          className={`${styles.input} !w-full`}
                        />
                      </div>
                      <div className="w-[50%] pl-2">
                        <label className="block pb-2">Email Address</label>
                        <input
                          type="email"
                          value={user && user.email}
                          required
                          className={`${styles.input} !w-full`}
                        />
                      </div>
                    </div>

                    <div className="w-full flex pb-3">
                      <div className="w-[50%] pr-2">
                        <label className="block pb-2">Phone Number</label>
                        <input
                          type="number"
                          required
                          value={user && user.phoneNumber}
                          className={`${styles.input} !w-full`}
                        />
                      </div>
                      <div className="w-[50%] pl-2">
                        <label className="block pb-2">Zip Code</label>
                        <input
                          type="number"
                          value={zipCode}
                          onChange={(e) => setZipCode(e.target.value)}
                          required
                          className={`${styles.input} !w-full`}
                        />
                      </div>
                    </div>

                    <div className="w-full flex pb-3">
                      <div className="w-[50%] pr-2">
                        <label className="block pb-2">Country</label>
                        <select
                          className="w-full border h-[40px] rounded-[5px]"
                          value={country}
                          onChange={(e) => {
                            setCountry(e.target.value);
                            setState(""); // Reset state when country changes
                            setCity(""); // Reset city when country changes
                          }}
                        >
                          <option className="block pb-2" value="">
                            Choose your country
                          </option>
                          {Country &&
                            Country.getAllCountries().map((item) => (
                              <option key={item.isoCode} value={item.isoCode}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                      <div className="w-[50%] pl-2">
                        <label className="block pb-2">State</label>
                        <select
                          className="w-full border h-[40px] rounded-[5px]"
                          value={state}
                          onChange={(e) => {
                            setState(e.target.value);
                            setCity(""); // Reset city when state changes
                          }}
                        >
                          <option className="block pb-2" value="">
                            Choose your state
                          </option>
                          {State &&
                            State.getStatesOfCountry(country).map((item) => (
                              <option key={item.isoCode} value={item.isoCode}>
                                {item.name}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                    <div className="w-full flex pb-3">
                      <div className="w-[50%]">
                        <label className="block pb-2">City</label>
                        <select
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          className="w-full border h-[40px] rounded-[5px]"
                        >
                          <option value="" className="block pb-2">
                            Choose your city
                          </option>
                          {City &&
                            City.getCitiesOfState(country, state).map(
                              (item) => (
                                <option key={item.isoCode} value={item.name}>
                                  {item.name}
                                </option>
                              )
                            )}
                        </select>
                      </div>
                    </div>

                    <div className="w-full flex pb-3">
                      <div className="w-[50%] pr-2">
                        <label className="block pb-2">Address1</label>
                        <input
                          type="address"
                          required
                          value={address1}
                          onChange={(e) => setAddress1(e.target.value)}
                          className={`${styles.input} !w-full`}
                        />
                      </div>
                      <div className="w-[50%] pl-2">
                        <label className="block pb-2">Address2 (Landmark)</label>
                        <input
                          type="address"
                          value={address2}
                          onChange={(e) => setAddress2(e.target.value)}
                          required
                          className={`${styles.input} !w-full`}
                        />
                      </div>
                    </div>
                  </form>
                  <h5
                    className="text-[18px] cursor-pointer inline-block"
                    onClick={() => setUserInfo(!userInfo)}
                  >
                    Choose From saved address
                  </h5>
                  {userInfo && (
                    <div>
                      {user &&
                        user.addresses.map((item, index) => (
                          <div className="w-full flex mt-1" key={index}>
                            <input
                              type="radio"
                              name="savedAddress"
                              className="mr-3"
                              value={index}
                              onClick={() => {
                                setAddress1(item.address1);
                                setAddress2(item.address2);
                                setZipCode(item.zipCode);
                                setCountry(item.country);
                                setState(item.state);
                                setCity(item.city);
                              }}
                            />
                            <h2>{item.addressType}</h2>
                          </div>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
            {/* </div> */}
            {/* </div> */}
          </div>
        );

      // case 3:
      //   return (
      //     <div className="max-w-lg mx-auto mt-11">
      //       <div className="bg-black rounded-lg shadow-md p-8 mt-11">
      //         <h2 className="text-2xl font-semibold mb-5">Upload Model Files</h2>
      //         <h5 className="font-semibold mb-11">
      //           <span className="text-gray-300">
      //             (Upload each model file with the name of Model Name)
      //           </span>
      //         </h5>
      //         <form onSubmit={handleSubmit}>
      //           <input
      //             type="file"
      //             onChange={handleFileChange}
      //             accept=".stl,.amf"
      //             className="mb-6"
      //             multiple
      //           />
      //         </form>
      //       </div>
      //     </div>
      //   );
      default:
        return null;
    }
  };

  return (
    <div>
      <Header />
      <h2 className="text-center text-3xl font-extrabold text-white mt-4">
        Quotation - 3D Printing
      </h2>
      <div className="px-4 py-5">{renderStepContent()}</div>
      <div className="flex justify-between px-4 pb-8">
        {step !== 1 && (
          <button
            onClick={handlePreviousStep}
            className="w-32 bg-white h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer text-black font-semibold"
          >
            Previous
          </button>
        )}
        <div className="flex-grow"></div>{" "}
        {/* This ensures the Next button moves to the right */}
        {step !== 2 && (
          <button
            onClick={handleNextStep}
            className="w-32 bg-white h-12 my-3 flex items-center justify-center rounded-xl cursor-pointer text-black font-semibold ml-auto"
          >
            Next
          </button>
        )}
        {step === 2 && (
          <button
          onClick={handleSubmit}
          type="submit"
          className={`w-32 h-12 my-3 flex items-center justify-center rounded-xl text-white font-semibold ${loading ? 'bg-blue-400 cursor-not-allowed' : 'bg-blue-600 hover:bg-blue-700'}`}
          disabled={loading}
        >
          {loading && (
            <svg className="animate-spin h-5 w-5 mr-3 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
              <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
              <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8h8a8 8 0 11-8-8z"></path>
            </svg>
          )}
          Submit
        </button>
        
        
        )}
      </div>

      <Footer />
    </div>
  );
};

export default Quotation3D;
