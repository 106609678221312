import React from "react";
import Footer from "../components/Layout/Footer";
import Header from "../components/Layout/Header";
import BlogDetail from "../components/Admin/BlogDetail.jsx";
import Loader from "../components/Layout/Loader.jsx";
import { useSelector } from "react-redux";

const BlogDetailPage = () => {
  const { loading } = useSelector((state) => state.user);

  return (
    <div className="min-h-screen">
      <Header />
      <div className="container mx-auto py-8">
        {loading ? <Loader /> : <BlogDetail />}
      </div>
      <Footer />
    </div>
  );
};

export default BlogDetailPage;
