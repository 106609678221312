import React from 'react'
import QuotationAskDetail from "../components/Quotation/QuotationAskDetail.jsx";
const QuotationAskDetailPage = () => {
  return (
    <div>
      <QuotationAskDetail/>
    </div>
  )
}

export default QuotationAskDetailPage
