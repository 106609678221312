import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineArrowRight, AiOutlineDelete } from "react-icons/ai";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import "./css/ProfileContent.css";
import {
  deleteUserAddress,
  updatUserAddress,
  updateUserInformation,
} from "../../redux/actions/user";
import { toast } from "react-toastify";
import { RxCross1 } from "react-icons/rx";
import { Country, State, City } from "country-state-city";
import axios from "axios";
import { server } from "../../server";
import { AiOutlineEye } from "react-icons/ai";
import StlViewers from "../AutoQuote/StlViewer";
// import {
//   deleteUserAddress,
//   loadUser,
//   updatUserAddress,
//   updateUserInformation,
// } from "../../redux/actions/user";
// import { backend_url } from "../../server";
const ProfileContent = ({ active }) => {
  const { user, error, successMessage } = useSelector((state) => state.user);
  const [name, setName] = useState(user && user.name);
  const [email, setEmail] = useState(user && user.email);
  const [phoneNumber, setPhoneNumber] = useState(user && user.phoneNumber);
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (error) {
      toast.error(error);
      dispatch({ type: "clearErrors" });
    }
    if (successMessage) {
      toast.success(successMessage);
      dispatch({ type: "clearMessages" });
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateUserInformation(name, email, phoneNumber, password));
  };
  return (
    <div className="w-full">
      {/* profile */}
      {active === 2 && (
        <>
          <div className="w-full px-5">
            <form onSubmit={handleSubmit}>
              <div className="w-full 800px:flex block pb-3 mb-10">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Full Name</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Email Address</label>
                  <input
                    type="text"
                    className={`${styles.input} !w-[95%] mb-1 800px:mb-0`}
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>
              <div className="w-full 800px:flex block pb-3 mb-10">
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Phone Number</label>
                  <input
                    type="number"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <div className=" w-[100%] 800px:w-[50%]">
                  <label className="block pb-2">Enter your password to update</label>
                  <input
                    type="password"
                    className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <input
                className={`w-[250px] h-[40px] border border-[#3eb7eb] text-center text-[#3eb7eb] rounded-[3px] mt-8 cursor-pointer`}
                required
                value="Update"
                type="submit"
              />
            </form>
          </div>
        </>
      )}

      {/* order */}
      {active === 3 && (
        <div>
          <AllOrders />
        </div>
      )}
      {/* inbox */}
      {active === 1 && (
        <div>
          <QuotationAsk />
        </div>
      )}

      {/* change password */}
      {active === 4 && (
        <div>
          <ChangePassword />
        </div>
      )}

      {/*  user Address */}
      {active === 5 && (
        <div>
          <Address />
        </div>
      )}
      {active === 8 && (
        <div>
          <AllVendors />
        </div>
      )}
    </div>
  );
};

const QuotationAsk = () => {
  const { user } = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []); // Empty dependency array to run the effect only once

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${server}/quote3D/orders`);
      // Filter orders to only include those of the current user and with status "Quote"
      // console.log(response)
      const userOrders = response.data.filter(
        (order) => order.user_id === user.id && order.orderStatus === "Quote"
      );
      setOrders(userOrders);
      // setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const columns = [
    { field: "order_id", headerName: "Order ID", minWidth: 250, flex: 0.7 },
    { field: "orderName", headerName: "Order Name", minWidth: 250, flex: 0.7 },
    // { field: "modelNameArray", headerName: "Model Names", minWidth: 250, flex: 1 },
    {
      field: "dateoforder",
      headerName: "Order Date",
      minWidth: 250,
      flex: 0.7,
    },
    {
      field: "orderStatus",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.row.orderStatus === "Completed"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "items",
      headerName: "Items",
      type: "number",
      minWidth: 150,
      flex: 0.7,
      valueGetter: (params) => (params.row.items ? params.row.items : 0),
    },
    // {
    //   field: "autoQuote",
    //   flex: 1,
    //   minWidth: 150,
    //   headerName: "Auto Quote",
    //   type: "number",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <div>
    //         {/* {params.row.quotation[0].files} */}
    //         {params.row.quotation.map((item) => (
    //           <Link to={`/onlinestlviewer/${item.files[0]}`}>
    //           <Button>
    //             <AiOutlineArrowRight size={20} />
    //           </Button>
    //         </Link>
    //         ))}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "actions",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/user/order/${params.row.order_id}`}>
            <Button>
              <AiOutlineArrowRight size={20} />
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows = orders.map((order) => ({
    id: order.id,
    orderName: order.orderName,
    // modelNameArray: order.quotation ? order.quotation.map(item => item.modelname) : [],
    dateoforder: order.dateoforder,
    order_id: order.order_id,
    items: order.quotation ? order.quotation.length : 0,
    orderStatus: order.orderStatus,
    quotation: order.quotation
  }));

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
        className="data-grid-text-white"
      />
    </div>
  );
};

const AllOrders = () => {
  const { user } = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    fetchOrders();
  }, []); // Empty dependency array to run the effect only once

  const fetchOrders = async () => {
    try {
      const response = await axios.get(`${server}/quote3D/orders`);
      // Filter orders to only include those of the current user and with status "Completed"
      // console.log(response)
      const userOrders = response.data.filter(
        (order) =>
          order.user_id === user.id && order.orderStatus === "Completed"
      );
      setOrders(userOrders);
      // setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const columns = [
    { field: "order_id", headerName: "Order ID", minWidth: 250, flex: 0.7 },
    { field: "orderName", headerName: "Order Name", minWidth: 250, flex: 0.7 },
    // { field: "modelNameArray", headerName: "Model Names", minWidth: 250, flex: 1 },
    {
      field: "dateoforder",
      headerName: "Order Date",
      minWidth: 250,
      flex: 0.7,
    },
    {
      field: "orderStatus",
      headerName: "Status",
      minWidth: 130,
      flex: 0.7,
      cellClassName: (params) => {
        return params.row.orderStatus === "Completed"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "items",
      headerName: "Items",
      type: "number",
      minWidth: 150,
      flex: 0.7,
      valueGetter: (params) => (params.row.items ? params.row.items : 0),
    },
    {
      field: "autoQuote",
      flex: 1,
      minWidth: 150,
      headerName: "Auto Quote",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/user/order/${params.row.order_id}`}>
            <Button>
              <AiOutlineArrowRight size={20} />
            </Button>
          </Link>
        );
      },
    },
    {
      field: "actions",
      flex: 1,
      minWidth: 150,
      headerName: "",
      type: "number",
      sortable: false,
      renderCell: (params) => {
        return (
          <Link to={`/user/order/${params.row.order_id}`}>
            <Button>
              <AiOutlineArrowRight size={20} />
            </Button>
          </Link>
        );
      },
    },
  ];

  const rows = orders.map((order) => ({
    id: order.id,
    orderName: order.orderName,
    // modelNameArray: order.quotation ? order.quotation.map(item => item.modelname) : [],
    dateoforder: order.dateoforder,
    order_id: order.order_id,
    items: order.quotation ? order.quotation.length : 0,
    orderStatus: order.orderStatus,
  }));

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
        className="data-grid-text-white"
      />
    </div>
  );
};

const AllVendors = () => {
  const { user } = useSelector((state) => state.user);
  const [vendors, setVendors] = useState([]);

  useEffect(() => {
    fetchVendors();
  }, []); // Empty dependency array to run the effect only once

  const fetchVendors = async () => {
    try {
      const response = await axios.get(`${server}/shop/getSellers`);
      // Filter orders to only include those of the current user and with status "Completed"
      // console.log(response)
      const allsellers = response.data.seller
      console.log(allsellers)
      setVendors(allsellers);
      // setOrders(response.data);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  const columns = [
    // { field: "id", headerName: "Seller ID", minWidth: 150, flex: 0.7 },
    { field: "name", headerName: "Name", minWidth: 130, flex: 0.7 },
    { field: "email", headerName: "Email", minWidth: 130, flex: 0.7 },
    { field: "phone", headerName: "Contact", minWidth: 130, flex: 0.7 },
    {
      field: "address",
      headerName: "Seller Address",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "city",
      headerName: "Seller City",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "state",
      headerName: "Seller State",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 130,
      flex: 0.7,
    },
    {
      field: "services",
      headerName: "Services",
      minWidth: 130,
      flex: 0.7,
    },
    { field: "joinedAt", headerName: "Joined At", minWidth: 130, flex: 0.8 },
    {
      field: "preview",
      headerName: "Preview Shop",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/shop/preview/${params.id}`}>
          <Button>
            <AiOutlineEye size={20} />
          </Button>
        </Link>
      ),
    },
  ];

  const rows = vendors.map((item) => ({
    id: item.id,
    name: item?.name,
    email: item?.email,
    phone: item.phoneNumber? item.phoneNumber: 'Not Found',
    joinedAt: item.createdAt.slice(0, 10),
    address: item.address? item.address : 'Not Found',
    state: item.state? item.state : 'Not Found',
    city: item.city? item.city : 'Not Found',
    description: item.description? item.description : 'Not Found',
    services: item.services.map((service) => {
      return service;
    })
  }));

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
        className="data-grid-text-white"
      />
    </div>
  );
};

const ChangePassword = () => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const passwordChangeHandler = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${server}/user/update-user-password`,
        { oldPassword, newPassword, confirmPassword },
        { withCredentials: true }
      );
      toast.success(response.data.message || "Password updated successfully!");
      setOldPassword("");
      setNewPassword("");
      setConfirmPassword("");
    } catch (error) {
      if (
        error.response &&
        error.response.data &&
        error.response.data.message
      ) {
        toast.error(error.response.data.message);
      } else {
        toast.error("An error occurred. Please try again.");
      }
    }
  };

  return (
    <div className="w-full px-5">
      <h1 className="block text-[25px] text-center font-[600] text-white pb-2">
        Change Password
      </h1>
      <div className="w-full">
        <form
          onSubmit={passwordChangeHandler}
          className="flex flex-col items-center"
        >
          <div className="w-[100%] 800px:w-[50%] mt-5">
            <label className="block pb-2">Enter your Password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
            />
          </div>
          <div className="w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your New Password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="w-[100%] 800px:w-[50%] mt-2">
            <label className="block pb-2">Enter your Confirm Password</label>
            <input
              type="password"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
            <input
              className="w-[95%] h-[40px] border border-[#3eb7eb] text-center text-[#3eb7eb] rounded-[3px] mt-8 cursor-pointer"
              value="Update"
              type="submit"
            />
          </div>
        </form>
      </div>
    </div>
  );
};

const Address = () => {
  const [open, setOpen] = useState(false);
  const [country, setCountry] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [address1, setAddress1] = useState("");
  const [address2, setAddress2] = useState("");
  const [addressType, setAddressType] = useState("");
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const addressTypeData = [
    { name: "Default" },
    { name: "Home" },
    { name: "Office" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      addressType === "" ||
      country === "" ||
      state === "" ||
      city === "" ||
      address1 === "" ||
      zipCode === ""
    ) {
      toast.error("Please fill all the fields!");
    } else {
      dispatch(
        updatUserAddress(
          country,
          state,
          city,
          address1,
          address2,
          zipCode,
          addressType
        )
      );
      setOpen(false);
      setCountry("");
      setState("");
      setCity("");
      setAddress1("");
      setAddress2("");
      setZipCode("");
      setAddressType("");
    }
  };
  const handleDelete = (item) => {
    const id = item.id;
    dispatch(deleteUserAddress(id));
  };

  useEffect(() => {
    if (country) {
      const countryStates = State.getStatesOfCountry(country);
      setStates(countryStates);
      setState("");
      setCity("");
      setCities([]);
    }
  }, [country]);

  useEffect(() => {
    if (state) {
      const stateCities = City.getCitiesOfState(country, state);
      setCities(stateCities);
      setCity("");
    }
  }, [state, country]);

  const [addressList, setAddressList] = useState([]);

  const getAddresses = async () => {
    const id = '1'
    const response = await axios.post(
      `${server}/user/getaddress`,
      { id },
    );
    setAddressList(prev => response.data.addresses)
  }

  useEffect(() => {
    getAddresses()
  }, [])

  useEffect(() => {
    console.log(addressList)
  })

  return (
    <div className="w-full px-5">
      {open && (
        <div className="fixed w-full h-screen bg-[#0000004b] top-0 left-0 flex items-center justify-center">
          <div className="w-[35%] h-[80vh] bg-black rounded shadow relative overflow-y-scroll">
            <div className="w-full flex justify-end p-3">
              <RxCross1
                size={30}
                className="cursor-pointer"
                onClick={() => setOpen(false)}
              />
            </div>
            <h1 className="text-center text-[25px] font-Poppins">
              Add New Address
            </h1>
            <div className="w-full">
              <form onSubmit={handleSubmit} className="w-full">
                <div className="w-full block p-4">
                  <div className="w-full pb-2">
                    <label className="block pb-2">Country</label>
                    <select
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="">Choose your country</option>
                      {Country.getAllCountries().map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">State</label>
                    <select
                      value={state}
                      onChange={(e) => setState(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="">Choose your state</option>
                      {states.map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">City</label>
                    <select
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="">Choose your city</option>
                      {cities.map((item) => (
                        <option key={item.isoCode} value={item.isoCode}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Address1</label>
                    <input
                      type="text"
                      className={`${styles.input}`}
                      required
                      value={address1}
                      onChange={(e) => setAddress1(e.target.value)}
                    />
                  </div>
                  <div className="w-full pb-2">
                    <label className="block pb-2">Address2 : (Landmark)</label>
                    <input
                      type="text"
                      className={`${styles.input}`}
                      value={address2}
                      onChange={(e) => setAddress2(e.target.value)}
                    />
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Zip Code</label>
                    <input
                      type="number"
                      className={`${styles.input}`}
                      required
                      value={zipCode}
                      onChange={(e) => setZipCode(e.target.value)}
                    />
                  </div>

                  <div className="w-full pb-2">
                    <label className="block pb-2">Address Type</label>
                    <select
                      value={addressType}
                      onChange={(e) => setAddressType(e.target.value)}
                      className="w-[95%] border h-[40px] rounded-[5px]"
                    >
                      <option value="">Choose your Address Type</option>
                      {addressTypeData.map((item) => (
                        <option key={item.name} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div className="w-full pb-2">
                    <input
                      type="submit"
                      className={`${styles.input} mt-5 cursor-pointer`}
                      value="Submit"
                      readOnly
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
      <div className="flex w-full items-center justify-between">
        <h1 className="text-[25px] font-[600] text-white pb-2">My Addresses</h1>
        <div
          className={`${styles.button} !rounded-mb`}
          onClick={() => setOpen(true)}
        >
          <span className="text-[#fff]">Add New</span>
        </div>
      </div>
      <br />
      {addressList.map((item, index) => (
          <div
            className="w-full bg-black h-min 800px:h-[70px] rounded-[4px] flex items-center px-3 shadow justify-between pr-10 mb-5"
            key={index}
          >
            <div className="flex items-center justify-start w-[20%]">
              <h5 className="font-[600]">{item.addressType}</h5>
            </div>
            <div className="flex items-center justify-start w-[50%]">
              <h6 className="text-[12px] 800px:text-[unset]">
                {item.address1}, {item.address2}
                <br />
                {item.city}, {item.state}, {item.zipCode}
              </h6>
            </div>
            <div className="flex items-center justify-start w-[20%]">
              <h6 className="text-[12px] 800px:text-[unset]">
                {user && user.phoneNumber}
              </h6>
            </div>
            <div className="min-w-[10%] flex items-center justify-between">
              <AiOutlineDelete
                size={25}
                className="cursor-pointer"
                onClick={() => handleDelete(item)}
              />
            </div>
          </div>
        ))}
      {addressList.length === 0 && (
        <h5 className="text-center pt-8 text-[18px]">
          You do not have any saved address!
        </h5>
      )}
    </div>
  );
};
export default ProfileContent;
