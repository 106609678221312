import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import { AiOutlineArrowRight } from "react-icons/ai";
import { server } from "../../server"; // Make sure this points to your server

const ShopMessage = () => {
  const [orders, setOrders] = useState([]);
  const { seller } = useSelector((state) => state.seller); // Assuming your Redux state has a seller object

  useEffect(() => {
    if (seller && seller.id) {
      fetchVendorCityAndOrders(seller.id);
    }
  }, [seller]);

  const fetchVendorCityAndOrders = async (vendorId) => {
    try {
      const cityResponse = await axios.get(`${server}/quote3D/vendorCity/${vendorId}`);
      const vendorCity = cityResponse.data.city;

      const ordersResponse = await axios.get(
        `${server}/quote3D/vorders?city=${vendorCity}`
      );
      setOrders(
        ordersResponse.data.filter((order) => order.orderStatus === "Quote")
      );
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };
  

  const columns = [
    { field: "order_id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    { field: "orderName", headerName: "Order Name", minWidth: 150, flex: 0.7 },
    {
      field: "dateoforder",
      headerName: "Order Date",
      minWidth: 250,
      flex: 0.7,
    },
    {
      field: "orderStatus",
      headerName: "Status",
      minWidth: 130,
      flex: 0.2,
      cellClassName: (params) => {
        return params.row.orderStatus === "Completed"
          ? "greenColor"
          : "redColor";
      },
    },
    {
      field: "items",
      headerName: "Items",
      type: "number",
      minWidth: 130,
      flex: 0.7,
    },
    // {
    //   field: "totalPrice",
    //   headerName: "Total",
    //   type: "number",
    //   minWidth: 130,
    //   flex: 0.8,
    // },
    {
      field: "actions",
      headerName: "",
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Link to={`/vendor/order/${params.row.order_id}`}>
          <Button>
            <AiOutlineArrowRight size={20} />
          </Button>
        </Link>
      ),
    },
  ];

  const rows = orders.map((order) => ({
    id: order.id,
    order_id: order.order_id,
    orderName: order.orderName,
    items: order.quotation ? order.quotation.length : 0,
    dateoforder: order.dateoforder,
    orderStatus: order.orderStatus,
    totalPrice: order.quotation
      ? order.quotation.reduce((acc, curr) => acc + curr.totalPrice, 0)
      : 0,
  }));

  return (
    <div className="pl-8 pt-1">
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={10}
        disableSelectionOnClick
        autoHeight
        className="data-grid-text-white"
      />
    </div>
  );
};

export default ShopMessage;
