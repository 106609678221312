import React from 'react'
import styles from '../../styles/styles'
import ShopInfo from "../../components/Shop/ShopInfo.jsx";
import ShopProfileData from "../../components/Shop/ShopProfileData.jsx";
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader.jsx";
const ShopHomePage = () => {
  return (
    <div>
      <DashboardHeader/>
      <div className={`${styles.section} bg-[#0e1421]`}>
         <div className="w-full flex  justify-between mt-2">
          <div className="w-[25%] bg-[#0e1421] rounded-[4px] shadow-sm overflow-y-scroll h-[85vh] sticky top-10 left-0 z-10">
            <ShopInfo isOwner={true} />
          </div>
          <div className="w-[72%] rounded-[4px]">
            <ShopProfileData isOwner={true} />
          </div>
         </div>
    </div>
  </div>
  )
}

export default ShopHomePage