import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';

const AboutUs = () => {
  useEffect(() => {
    const sr = ScrollReveal({ reset: false });
    sr.reveal('.about-heading', { origin: 'right', distance: '80px', duration: 1000, delay: 50 });
    sr.reveal('.about-paragraph', { origin: 'right', distance: '80px', duration: 1000, delay: 50 });
    sr.reveal('.about-list', { origin: 'right', distance: '80px', duration: 1000, delay: 50 });
    sr.reveal('.about-subheading', { origin: 'right', distance: '80px', duration: 1000, delay: 50 });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="min-h-screen flex items-center justify-center">
      <div className="px-4 py-8 md:p-12 shadow-md rounded-lg max-w-4xl w-full bg-white mx-4 sm:mx-8">
        <div className="max-w-3xl mx-auto">
          <section className="mb-8">
            <h2 className="about-heading text-3xl md:text-4xl font-bold text-gray-800 mb-4">About Us</h2>
            <p className="about-paragraph text-gray-700 mb-4">
              Welcome to <strong className="text-blue-600">BharatMaker!</strong> At BharatMaker, we specialize in connecting customers with top-tier 3D printing service providers. Whether you're looking for rapid prototyping, intricate designs, or customized solutions, we've got you covered.
            </p>
          </section>

          <section className="mb-8">
            <h3 className="about-subheading text-2xl md:text-3xl font-semibold text-gray-800 mb-2">Why Partner with Us?</h3>
            <div className="about-subheading bg-white shadow-md rounded-lg p-6 mb-4">
              <h4 className="text-xl font-semibold text-gray-800 mb-2">Platform Hosting</h4>
              <p className="text-gray-700 mb-4">
                We offer a unique platform for 3D printing service providers to showcase their offerings. By partnering with BharatMaker, your services gain exposure to a broad client base, all under our trusted brand name.
              </p>
              <h4 className="text-xl font-semibold text-gray-800 mb-2">Brand Exposure</h4>
              <p className="text-gray-700 mb-4">
                All services listed with us are prominently featured, ensuring maximum visibility and credibility among potential clients.
              </p>
              <p className="text-gray-700 mb-4">
                <Link to="/" className="text-blue-500 hover:underline">Interested?</Link> Click here to learn more about how we can amplify your reach in the 3D printing industry.
              </p>
            </div>
          </section>

          <section className="mb-8">
            <h3 className="about-subheading text-2xl md:text-3xl font-semibold text-gray-800 mb-2">Benefits of Choosing BharatMaker</h3>
            <ul className="about-list list-disc list-inside text-gray-700">
              <li className="mb-2">Access to Reputed Providers: We connect you with established 3D printing service providers renowned for their quality and reliability.</li>
              <li className="mb-2">Vendor Selection: Choose from a diverse range of vendors based on criteria such as pricing, delivery speed, quality standards, and more.</li>
              <li>Direct Communication: Easily communicate with vendors for inquiries, ensuring seamless collaboration and clarity throughout the process.</li>
            </ul>
          </section>

          <section>
            <h2 className="about-heading text-3xl md:text-4xl font-bold text-gray-800 mb-4">Who We Are</h2>
            <p className="about-paragraph text-gray-700 mb-4">
              BharatMaker was born out of a passion for manufacturing innovation. Having navigated challenges in the prototyping process ourselves, we understand the importance of accessible and reliable 3D printing solutions.
            </p>
            <p className="about-paragraph text-gray-700">
              <strong className="text-blue-500">Our Commitment</strong> BharatMaker is committed to facilitating your journey in the 3D printing world, eliminating obstacles and delivering exceptional results.
            </p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
