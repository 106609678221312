import ScrollReveal from "scrollreveal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import React, { useEffect } from "react";
const Service = () => {
  const { isAuthenticated } = useSelector((state) => state.user);
  useEffect(() => {
    const sr = ScrollReveal({ reset: false });
    sr.reveal(".about-heading", {
      origin: "right",
      distance: "80px",
      duration: 1000,
      delay: 50,
    });
    sr.reveal(".about-paragraph", {
      origin: "right",
      distance: "80px",
      duration: 1000,
      delay: 50,
    });
    sr.reveal(".about-list", {
      origin: "right",
      distance: "80px",
      duration: 1000,
      delay: 50,
    });
    sr.reveal(".about-subheading", {
      origin: "right",
      distance: "80px",
      duration: 1000,
      delay: 50,
    });
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="min-h-screen flex flex-col items-center justify-center py-8">
      <div className="max-w-4xl w-full bg-white shadow-md rounded-lg p-6 md:p-12 mx-4 sm:mx-8">
        <div className="max-w-3xl mx-auto px-4">
          <section className="mb-8">
            <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4 about-heading">
              Our Services
            </h2>
            <p className="text-gray-700 mb-4 about-heading">
              Discover a wide range of 3D printing services through{" "}
              <strong className="text-blue-600">BharatMaker</strong>. We connect
              you with top-tier vendors specializing in rapid prototyping,
              custom designs, functional parts, and more.
            </p>
          </section>

          <section className="mb-8">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 about-heading">
              <div className="bg-white shadow-md rounded-lg p-6">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Rapid Prototyping
                </h4>
                <p className="text-gray-700 mb-4">
                  Connect with vendors who specialize in rapid prototyping for
                  quick and cost-effective development of prototypes.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-lg p-6">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Custom Designs
                </h4>
                <p className="text-gray-700 mb-4">
                  Get customized solutions tailored to your specific
                  requirements, designed and manufactured by experienced
                  professionals.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-lg p-6">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Functional Parts
                </h4>
                <p className="text-gray-700 mb-4">
                  Explore vendors capable of producing functional parts with
                  high strength and precision, suitable for various
                  applications.
                </p>
              </div>
              <div className="bg-white shadow-md rounded-lg p-6">
                <h4 className="text-xl font-semibold text-gray-800 mb-2">
                  Design Consultation
                </h4>
                <p className="text-gray-700 mb-4">
                  Receive expert guidance on optimizing your designs for 3D
                  printing, ensuring efficient production and superior quality.
                </p>
              </div>
            </div>
          </section>

          <section>
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-2 about-heading">
              Why Choose BharatMaker?
            </h3>
            <ul className="list-disc list-inside text-gray-700 about-heading">
              <li className="mb-2">
                Curated Vendors: We partner with reputable vendors renowned for
                their quality and reliability in 3D printing.
              </li>
              <li className="mb-2">
                Diverse Solutions: Access a diverse range of 3D printing
                solutions tailored to meet various project needs.
              </li>
              <li className="mb-2">
                Quality Assurance: Ensure high standards of production with
                vendors vetted for their expertise and technology.
              </li>
              <li className="mb-2">
                Competitive Pricing: Receive multiple quotes from various
                vendors, allowing you to choose the best price and service
                combination that fits your needs.
              </li>
              <li>
                Efficient Process: Streamlined processes for quick turnaround
                times without compromising on quality.
              </li>
            </ul>
          </section>

          <section className="mt-8 about-heading">
            <h3 className="text-2xl md:text-3xl font-semibold text-gray-800 mb-4">
              Get Started Today!
            </h3>
            <p className="text-gray-700 mb-4">
              Ready to explore our 3D printing services? Generate a quote today
              and let <strong className="text-blue-600">BharatMaker</strong>{" "}
              connect you with the perfect vendor to bring your projects to
              life.
            </p>
            <div className="flex justify-center">
              {isAuthenticated ? (
                <Link
                  to="/quote-3D"
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Get Quote
                </Link>
              ) : (
                <Link
                  to="/login"
                  className="bg-blue-500 text-white px-6 py-2 rounded-lg hover:bg-blue-600 transition duration-300"
                >
                  Get Quote
                </Link>
              )}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default Service;
