// ShopDashboard.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import { server } from "../../server"; // Make sure this points to your server
import { RiArrowRightSLine } from "react-icons/ri";
import { Link } from "react-router-dom";
const ShopDashboard = () => {
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [ongoingOrdersCount, setOngoingOrdersCount] = useState(0);
  const { seller } = useSelector((state) => state.seller);

  useEffect(() => {
    if (seller && seller.id) {
      console.log(seller)
      console.log(seller.id)
      fetchVendorCityAndOrders(seller.id);
    }
  }, []);

  const fetchVendorCityAndOrders = async (vendorId) => {
    try {
      const cityResponse = await axios.get(`${server}/quote3D/vendorCity/${vendorId}`);
      const vendorCity = cityResponse.data.city;

      const ordersResponse = await axios.get(
        `${server}/quote3D/vorders?city=${vendorCity}`
      );

      const completedOrders = ordersResponse.data.filter(
        (order) =>
          order.orderStatus === "Completed" &&
          order.prices.some((price) => price.vendor_id === seller.id.toString())
      );

      const ongoingOrders = ordersResponse.data.filter(
        (order) => order.orderStatus === "Quote"
      );

      setCompletedOrdersCount(completedOrders.length);
      setOngoingOrdersCount(ongoingOrders.length);
    } catch (error) {
      console.error("Error fetching orders:", error);
    }
  };

  return (
    <div className="p-6 bg-gray-900 rounded-lg shadow-lg">
  <h2 className="text-3xl font-semibold text-gray-200 mb-6">Dashboard</h2>
  <div className="grid grid-cols-2 gap-6">
    <div className="bg-blue-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
      <div>
        <p className="text-lg font-semibold mb-2">Completed Orders</p>
        <p className="text-3xl font-bold text-blue-300">{completedOrdersCount}</p>
      </div>
      <Link to="/dashboard-orders" className="text-sm text-gray-400 flex items-center space-x-1">
        <span>View</span>
        <RiArrowRightSLine size={20} />
      </Link>
    </div>
    <div className="bg-green-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
      <div>
        <p className="text-lg font-semibold mb-2">Ongoing Orders</p>
        <p className="text-3xl font-bold text-green-300">{ongoingOrdersCount}</p>
      </div>
      <Link to="/dashboard-messages" className="text-sm text-gray-400 flex items-center space-x-1">
        <span>View</span>
        <RiArrowRightSLine size={20} />
      </Link>
    </div>
  </div>
</div>
  );
};

export default ShopDashboard;
