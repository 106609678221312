import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { server } from '../../server';
import { useNavigate } from 'react-router-dom';
import ScrollReveal from 'scrollreveal';

const Blog = () => {
  const navigate = useNavigate();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    fetchBlogs();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);

    const sr = ScrollReveal({ reset: false });
    sr.reveal('.blog-card', { 
      origin: 'right', 
      distance: '80px', 
      duration: 1000, 
      delay: 100,
      interval: 200
    });
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get(`${server}/blog/all-blogs`, {
        withCredentials: true,
      });
      setBlogs(response.data.blogs);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const handleBlogClick = (blogId) => {
    navigate(`/blogs/${blogId}`);
  };

  const truncateContent = (content, maxLength) => {
    if (content.length > maxLength) {
      return `${content.substring(0, maxLength)}...`;
    }
    return content;
  };

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8 p-8">
      {blogs.map((blog) => (
        <div
          key={blog.id}
          className="blog-card bg-white rounded-lg shadow-md overflow-hidden hover:shadow-xl transition duration-300 transform hover:scale-105 cursor-pointer"
          onClick={() => handleBlogClick(blog.id)}
        >
          <div className="relative h-64">
            <img
              className="h-full w-full object-cover"
              src={blog.image}
              alt={blog.title}
            />
          </div>
          <div className="p-6">
            <h4 className="text-xl font-bold mb-3 text-gray-800">{blog.title}</h4>
            <div className="text-gray-600 mb-4" dangerouslySetInnerHTML={{ __html: truncateContent(blog.content, 150) }} />
            <div className="text-sm text-gray-500">
              <p className="mb-1">Author: {blog.author}</p>
              <p>Created At: {new Date(blog.createdAt).toLocaleDateString()}</p>
            </div>
            {blog.content.length > 150 && (
              <button className="text-blue-500 hover:underline focus:outline-none">
                Read more
              </button>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Blog;
