import React from 'react'
import Quotation3D from "../components/Quotation/Quotation3D.jsx";
const QuotationPage3D = () => {
  return (
    <div>
      <Quotation3D/>
    </div>
  )
}

export default QuotationPage3D
