import React from 'react'
import QuotationLaser from "../components/Quotation/QuotationLaser.jsx";
const QuotationPageLaser = () => {
  return (
    <div>
      <QuotationLaser/>
    </div>
  )
}

export default QuotationPageLaser
