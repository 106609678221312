import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { CheckCircle2 } from "lucide-react";
import printer from "../Images/printer.jpg";
// import laser from "../Images/laser.jpg";
import { useSelector } from "react-redux";
import ScrollReveal from 'scrollreveal';

const Feature = () => {
  const { isAuthenticated } = useSelector((state) => state.user);

  useEffect(() => {
    const sr = ScrollReveal({ reset: false });
    sr.reveal('.feature-heading', { origin: 'top', distance: '80px', duration: 2000, delay: 200 });
    sr.reveal('.feature-image', { origin: 'bottom', distance: '80px', duration: 2000, delay: 200 });
    sr.reveal('.feature-content', { origin: 'left', distance: '80px', duration: 2000, delay: 200 });
  }, []);

  return (
    <div >
      <h2 className="feature-heading text-3xl sm:text-5xl lg:text-6xl text-center mt-6 tracking-wide">
        Services at{" "}
        <span className="bg-gradient-to-r from-blue-500 to-blue-600 text-transparent bg-clip-text">
          BharatMaker
        </span>
      </h2>
      <div className="flex flex-wrap justify-center">
        <div className="feature-image p-2 w-full lg:w-1/2 lg:pl-40 lg:pr-20">
          <img src={printer} alt="Printer" className="mx-auto lg:ml-auto lg:mr-0 w-full lg:w-auto" />
        </div>
        <div className="feature-content pt-12 w-full lg:w-1/2">
          <div className="flex mb-12">
            <div className="text-green-400 mx-6 bg-neutral-900 h-10 w-10 p-2 justify-center items-center rounded-full">
              <CheckCircle2 />
            </div>
            <div>
              <h5 className="mt-1 mb-2 text-xl">3D-Printing</h5>
              <p className="text-md text-neutral-500">
                3D Printing Facility is equipped with high quality FDM Printers
                that provides latest technology for Prototyping and
                Manufacturing.
              </p>
            </div>
          </div>
          <div className="flex mb-12">
            <div className="text-green-400 mx-6 bg-neutral-900 h-10 w-10 p-2 justify-center items-center rounded-full">
              <CheckCircle2 />
            </div>
            <div>
              <h5 className="mt-1 mb-2 text-xl">Generate your Quotation</h5>
              <p className="text-md text-neutral-500">
                Click the link below to generate your Quote
              </p>
            </div>
          </div>
          <div className="flex mb-12 ml-12 mt-0">
            <div className="flex justify-center my-10">
              <div className="bg-gradient-to-r from-blue-700 to-blue-900 py-3 px-4 mx-3 rounded-md">
                {isAuthenticated ? (
                  <Link to="/quote-3D" className="text-white">
                    Get Quote
                  </Link>
                ) : (
                  <Link to="/login" className="text-white">
                    Get Quote
                  </Link>
                )}
              </div>
            </div>
            <div className="flex justify-center my-10">
              <div className="bg-gradient-to-r from-blue-700 to-blue-900 py-3 px-4 mx-3 rounded-md">
              <Link to="/onlinestlviewer" className="text-white">
                    3d Model Viewer
                  </Link>
                {/* {isAuthenticated ? (
                  <Link to="/onlinestlviewer" className="text-white">
                    3d Model Viewer
                  </Link>
                ) : (
                  <Link to="/login" className="text-white">
                    3d Model Viewer
                  </Link>
                )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="flex flex-wrap justify-center">
        <div className="feature-image p-2 w-full lg:w-1/2 lg:pl-40 lg:pr-20">
          <img src={laser} alt="Laser" className="mx-auto lg:ml-auto lg:mr-0 w-full lg:w-auto" />
        </div>
        <div className="feature-content pt-12 w-full lg:w-1/2">
          <div className="flex mb-12">
            <div className="text-green-400 mx-6 bg-neutral-900 h-10 w-10 p-2 justify-center items-center rounded-full">
              <CheckCircle2 />
            </div>
            <div>
              <h5 className="mt-1 mb-2 text-xl">Laser Cutting</h5>
              <p className="text-md text-neutral-500">
                Laser Cutting Facility is equipped with advanced Laser Cutting
                and Engraving Machines that provides highly precise rapid
                prototyping with various materials.
              </p>
            </div>
          </div>
          <div className="flex mb-12">
            <div className="text-green-400 mx-6 bg-neutral-900 h-10 w-10 p-2 justify-center items-center rounded-full">
              <CheckCircle2 />
            </div>
            <div>
              <h5 className="mt-1 mb-2 text-xl">Generate your Quotation</h5>
              <p className="text-md text-neutral-500">
                Click the link below to generate your Quote
              </p>
            </div>
          </div>
          <div className="flex mb-12 ml-12 mt-0">
            <div className="flex justify-center my-10">
              <div className="bg-gradient-to-r from-blue-700 to-blue-900 py-3 px-4 mx-3 rounded-md">
                {isAuthenticated ? (
                  <Link to="/quote-laser" className="text-white">
                    Get Quote
                  </Link>
                ) : (
                  <Link to="/login" className="text-white">
                    Get Quote
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Feature;
