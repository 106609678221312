import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import { server } from '../../server';
import { FaShop } from 'react-icons/fa6';

const Forgot = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); // Add loading state

  const handleResetPasswordRequest = async () => {
    try {
      setLoading(true); // Start loading
      const response = await axios.post(`${server}/user/reset-password-request`, {
        name,
        email,
      });
      setMessage(response.data.message);
      toast.success('Password reset email sent!');
    } catch (error) {
      setMessage(error.response.data.message);
      toast.error('Failed to reset password.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div className="min-h-screen flex flex-col bg-[#111827]  items-center pt-20">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
        <h2 className="text-3xl font-extrabold text-center text-gray-800 mb-6">Forget Password</h2>
        <div className="space-y-4">
          <div>
            <label htmlFor="name" className="block text-sm font-medium text-black">
              Name
            </label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder="Enter your name"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-gray-900"
            />
          </div>
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-black">
              Email Address
            </label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white text-gray-900"
            />
          </div>
          <button
            onClick={handleResetPasswordRequest}
            className={`w-full bg-blue-600 hover:bg-blue-700 text-white py-2 px-4 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 ${
              loading ? 'opacity-50 cursor-not-allowed' : '' // Disable button and change cursor when loading
            }`}
            disabled={loading} // Disable button when loading
          >
            {loading ? (
              <div className="flex items-center">
                <div className="w-4 h-4 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                Sending Email...
              </div>
            ) : (
              'Reset Password'
            )}
          </button>
          {message && <p className="text-red-500 text-sm">{message}</p>}
          <Link to="/login" className="text-blue-600 hover:text-blue-500 block text-center font-medium">
            Back to Login
          </Link>
          <div className="flex mt-4">
            <Link to="/shop-login" className="text-blue-600 font-medium">
              <FaShop className="text-blue-600 text-2xl mr-2 " />
            </Link>
            <Link to="/forget-password-shop" className="text-blue-600 font-medium">
              Vendor Forget Password
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Forgot;
