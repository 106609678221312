import React, { useEffect } from 'react';
import { Link } from "react-router-dom";
import ScrollReveal from 'scrollreveal';

const HeroSection = () => {
  useEffect(() => {
    const sr = ScrollReveal({ reset: false });
    sr.reveal('.hero-heading', { origin: 'top', distance: '80px', duration: 2000, delay: 200 });
    sr.reveal('.hero-subheading', { origin: 'bottom', distance: '80px', duration: 2000, delay: 200 });
    sr.reveal('.hero-paragraph', { origin: 'left', distance: '80px', duration: 2000, delay: 200 });
    sr.reveal('.hero-button', { origin: 'right', distance: '80px', duration: 2000, delay: 200 });
  }, []);

  return (
    <div className="flex justify-center items-center h-screen">
      <div className="flex flex-col items-center">
        <h1 className="hero-heading text-4xl sm:text-5xl lg:text-6xl text-center tracking-wide">
        A marketplace for all your <span className="bg-gradient-to-r from-blue-500 to-blue-600 text-transparent bg-clip-text">
            {" "}3D Printing
          </span> needs
        </h1>
        <p className="hero-paragraph mt-10 text-sm sm:text-lg lg:text-xl text-center text-neutral-500 max-w-4xl ">
          Your gateway to bespoke manufacturing. Discover, design, and print with confidence.
        </p>
        <div className="hero-button flex justify-center my-10">
          <Link to="/services" className="bg-gradient-to-r from-blue-500 to-blue-800 py-3 px-4 mx-3 rounded-md">
            Our Services
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
