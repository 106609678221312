import React from 'react'
import Header from "../components/Layout/Header.jsx";
import ForgotShop from "../components/Forgot/ForgotShop.jsx";
import Footer from "../components/Layout/Footer.jsx";
const ForgotShopPage = () => {
  return (
    <div className='w-full h-screen bg-[#111827]'>
      <Header/>
      <ForgotShop/>
      <Footer/>
    </div>
  )
}

export default ForgotShopPage