import React from 'react';
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader.jsx";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar.jsx";
import ShopPassword from "../../components/Shop/ShopPassword.jsx";

const ShopPasswordPage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-start w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={5} />
        </div>
        <div className="flex-grow">
          <ShopPassword />
        </div>
      </div>
    </div>
  );
}

export default ShopPasswordPage;