import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { server } from '../../server';

const BlogDetail = () => {
  const { id } = useParams();
  const [blog, setBlog] = useState(null);

  useEffect(() => {
    fetchBlog();
  }); // Ensure useEffect runs when id changes

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fetchBlog = async () => {
    try {
      const response = await axios.get(`${server}/blog/blog/${id}`, {
        withCredentials: true,
      });
      setBlog(response.data.blog);
    } catch (error) {
      console.error('Error fetching blog:', error);
    }
  };

  if (!blog) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="p-8 text-center text-gray-800">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex justify-center items-center py-10">
      <div className="p-6 md:p-8 bg-white rounded-lg shadow-lg max-w-4xl w-full">
        <h1 className="text-4xl font-bold text-gray-900 mb-4">{blog.title}</h1>
        <img
          src={blog.image}
          alt={blog.title}
          className="w-full md:w-96 rounded-lg mb-4 object-cover object-center"
          style={{ maxHeight: '300px' }}
        />
        <div
          className="text-lg text-gray-800 leading-relaxed mb-6"
          dangerouslySetInnerHTML={{ __html: blog.content }}
        />
        <div className="text-sm text-gray-700">
          <p className="mb-1">Author: {blog.author}</p>
          <p>Published: {new Date(blog.createdAt).toLocaleDateString()}</p>
        </div>
      </div>
    </div>
  );
};

export default BlogDetail;
