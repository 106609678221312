import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import upiScannerImage from "../assests/qr-code.jpg";
import "./PaymentDetails.css";
import Header from "../Header/Header";

const PaymentDetails = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    const [formData, setFormData] = useState({
      name: "",
      mobile: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      amount: 0,
      upiId: "",
    });
    const [errors, setErrors] = useState({
      name: "",
      mobile: "",
      email: "",
      address: "",
      city: "",
      state: "",
      pincode: "",
      upiId: "",
    });
    const [paymentDone, setPaymentDone] = useState(false);
  
    useEffect(() => {
      if (location.state) {
        const { price, whiteQuantity ,blackQuantity } = location.state;
        setFormData((prevFormData) => ({
          ...prevFormData,
          amount: ((price * whiteQuantity) + (price * blackQuantity)).toFixed(2),
          
        }));
        console.log("price", price);
        console.log("White quantity", whiteQuantity);
        console.log("black qauntity", blackQuantity);
      }
    }, [location.state]);
  
    
  
    const handlePaymentSubmit = (event) => {
      event.preventDefault();
      if (validatePaymentForm()) {
        console.log("Payment Form submitted with data:", formData);
        setPaymentDone(true);
        // alert("Order Placed Successfully")
        setTimeout(() => {
          navigate("/");
        }, 2000); 
      } else {
        console.log("Payment Form has errors. Please correct them.");
      }
    };
  
    const handleInputChange = (event) => {
      const { name, value } = event.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
  
    const validatePaymentForm = () => {
      let valid = true;
      const newErrors = { ...errors };
  
      if (!formData.name.trim()) {
          newErrors.name = "Name is required";
          valid = false;
        } else {
          newErrors.name = "";
        }
    
        if (!formData.mobile.trim() || formData.mobile.length !== 10) {
          newErrors.mobile = "Please enter a valid 10-digit mobile number";
          valid = false;
        } else {
          newErrors.mobile = "";
        }
    
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!formData.email.trim() || !emailPattern.test(formData.email)) {
          newErrors.email = "Please enter a valid email address";
          valid = false;
        } else {
          newErrors.email = "";
        }
    
        if (!formData.address.trim()) {
          newErrors.address = "Address is required";
          valid = false;
        } else {
          newErrors.address = "";
        }
    
        if (!formData.city.trim()) {
          newErrors.city = "City is required";
          valid = false;
        } else {
          newErrors.city = "";
        }
    
        if (!formData.state.trim()) {
          newErrors.state = "Please select a state";
          valid = false;
        } else {
          newErrors.state = "";
        }
    
        if (!formData.pincode.trim() || formData.pincode.length !== 6) {
          newErrors.pincode = "Please enter a valid 6-digit pincode";
          valid = false;
        } else {
          newErrors.pincode = "";
        }
  
      setErrors(newErrors);
      return valid;
    };
  
    return (
      <div>
        <Header />
        <hr className="horizontal-line" />
        <div className="payment-details">
          <h1>Payment and Address Details</h1>
          <div >
            <h3 className="toggle-header">
              <b>
                <i>Address Details</i>
              </b>
            </h3>
            <form onSubmit={handlePaymentSubmit}>
              <div className="address-details">
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="name">Name:*</label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      value={formData.name}
                      onChange={handleInputChange}
                    />
                    {errors.name && <div className="error">{errors.name}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="mobile">Mobile No:*</label>
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      value={formData.mobile}
                      onChange={handleInputChange}
                    />
                    {errors.mobile && (
                      <div className="error">{errors.mobile}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="address">Address:*</label>
                    <textarea
                      id="address"
                      name="address"
                      value={formData.address}
                      onChange={handleInputChange}
                      className="address"
                    ></textarea>
                    {errors.address && (
                      <div className="error">{errors.address}</div>
                    )}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="city">City:*</label>
                    <input
                      type="text"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleInputChange}
                    />
                    {errors.city && <div className="error">{errors.city}</div>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="state">State:*</label>
                    <select
                      id="state"
                      name="state"
                      value={formData.state}
                      onChange={handleInputChange}
                      className="state"
                    >
                      <option value="">Select State*</option>
                      <option value="Andhra Pradesh">Andhra Pradesh</option>
                      <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                      <option value="Assam">Assam</option>
                      <option value="Bihar">Bihar</option>
                      <option value="Chhattisgarh">Chhattisgarh</option>
                      <option value="Goa">Goa</option>
                      <option value="Gujarat">Gujarat</option>
                      <option value="Haryana">Haryana</option>
                      <option value="Himachal Pradesh">Himachal Pradesh</option>
                      <option value="Jharkhand">Jharkhand</option>
                      <option value="Karnataka">Karnataka</option>
                      <option value="Kerala">Kerala</option>
                      <option value="Madhya Pradesh">Madhya Pradesh</option>
                      <option value="Maharashtra">Maharashtra</option>
                      <option value="Manipur">Manipur</option>
                      <option value="Meghalaya">Meghalaya</option>
                      <option value="Mizoram">Mizoram</option>
                      <option value="Nagaland">Nagaland</option>
                      <option value="Odisha">Odisha</option>
                      <option value="Punjab">Punjab</option>
                      <option value="Rajasthan">Rajasthan</option>
                      <option value="Sikkim">Sikkim</option>
                      <option value="Tamil Nadu">Tamil Nadu</option>
                      <option value="Telangana">Telangana</option>
                      <option value="Tripura">Tripura</option>
                      <option value="Uttar Pradesh">Uttar Pradesh</option>
                      <option value="Uttarakhand">Uttarakhand</option>
                      <option value="West Bengal">West Bengal</option>
                    </select>
                    {errors.state && <div className="error">{errors.state}</div>}
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor="pincode">Pincode:*</label>
                    <input
                      type="text"
                      id="pincode"
                      name="pincode"
                      value={formData.pincode}
                      onChange={handleInputChange}
                    />
                    {errors.pincode && (
                      <div className="error">{errors.pincode}</div>
                    )}
                  </div>
                  <div className="form-group">
                    <label htmlFor="email">Email:*</label>
                    <input
                      type="text"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && <div className="error">{errors.email}</div>}
                  </div>
                </div>
              </div>
              <br />
              <br />
              <div className="payment-form">
                <h3 className="toggle-header">
                  <b>
                    <i>Payment Details</i>
                  </b>
                </h3>
                <div className="amount">
                  <label htmlFor="totalPrice">
                    <b><i>Amount: ₹{" "}
                    {formData.amount}
                    </i></b>
                  </label>
                  
                </div>
                <div className="upi">
                  <label htmlFor="upiId">
                    <b>UPI ID:* </b>
                  </label>
                  <input
                    type="text"
                    id="upiId"
                    name="upiId"
                    value={formData.upiId}
                    onChange={handleInputChange}
                  
                    className="upi-input"
                    
                  />
                  <p className="upi-content"><i>(Please provide UPI from which you are doing payment)</i></p>
                </div>
                <br />
                <br />
                <div className="payment-method">
                <div className="pay-label">
              <label htmlFor="">
                <b>Pay to this UPI: </b>
              </label>
              <h4 className="upi-id"><b><i>ishaantak99@okhdfcbank</i></b></h4>
            </div>
            <h3 className="or-label">OR</h3>
                  <div >
                  <label htmlFor="scanner">
                    <b> Scan to Pay: </b>
                  </label>
                </div>
               </div>
                <div className="scan-container">
                  <img
                    src={upiScannerImage}
                    alt="Scan UPI QR Code"
                    className="scanner"
                  />
                  <small>Scan this QR code for payment</small>
                </div>
                <button
                  type="submit"
                  className="submit-button"
                  
                >
                  Submit
                </button>
              </div>
              {paymentDone && (
            <div className="payment-done">
              <p>Payment Done! ✅</p>
              <p>Redirecting to homepage...</p>
            </div>
          )}
            </form>
          </div>
        </div>
      </div>
    );
  };
  

export default PaymentDetails;
