import React from 'react';
import DashboardHeader from "../../components/Shop/Layout/DashboardHeader.jsx";
import DashboardSideBar from "../../components/Shop/Layout/DashboardSideBar.jsx";
import ShopMessage from "../../components/Shop/ShopMessage.jsx";

const ShopMessagePage = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="flex items-start w-full">
        <div className="w-[80px] 800px:w-[330px]">
          <DashboardSideBar active={3} />
        </div>
        <div className="flex-grow">
          <ShopMessage />
        </div>
      </div>
    </div>
  );
}

export default ShopMessagePage;
