import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedAdminRoute = ({ children }) => {
  const { loading, isAuthenticated, user } = useSelector((state) => state.user);

  // Debugging logs
  console.log("Loading:", loading);
  console.log("isAuthenticated:", isAuthenticated);
  console.log("User Role:", user && user.role);

  if (loading === false) {
    if (!isAuthenticated) {
      return <Navigate to="/login" replace />;
    } else if (user.role !== "Admin") {
      return <Navigate to="/" replace />;
    }
    return children;
  }

  return null; // Or a loading indicator
};

export default ProtectedAdminRoute;
