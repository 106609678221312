import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import Modal from "react-modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile, faDownload } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const QuotationAskDetailShop = () => {
  const { order_id } = useParams();
  const [order, setOrder] = useState(null);
  const [modelPrices, setModelPrices] = useState({});
  const [deliveryDate, setDeliveryDate] = useState("");
  const [deliveryCharge, setDeliveryCharge] = useState("");
  const [description, setDescription] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [gstAmount, setGstAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);

  const { seller } = useSelector((state) => state.seller);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(`${server}/quote3D/order/${order_id}`);
        setOrder(response.data);

        const submittedPrice = response.data.prices.find(
          (p) => p.vendor_id === seller.id
        );
        if (submittedPrice) {
          setHasSubmitted(true);
          // Map the submitted prices to modelPrices object
          const prices = {};
          submittedPrice.modelPrices.forEach((price) => {
            prices[price.modelname] = price.price;
          });
          setModelPrices(prices);
          setDeliveryDate(submittedPrice.deliveryDate);
          setDeliveryCharge(submittedPrice.deliveryCharge);
          setDescription(submittedPrice.description);
          setGstAmount(submittedPrice.gstAmount);
          setTotalAmount(submittedPrice.totalAmount);
        }
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    if (seller) {
      fetchOrderDetails();
    }
  }, [order_id, seller]);

  const handlePriceChange = (modelName, price) => {
    setModelPrices((prevPrices) => ({
      ...prevPrices,
      [modelName]: price,
    }));
  };

  const calculateTotals = () => {
    const totalModelPrices = Object.values(modelPrices).reduce(
      (sum, price) => sum + Number(price || 0),
      0
    );
    const calculatedGstAmount = totalModelPrices * 0.18;
    const calculatedTotalAmount =
      totalModelPrices + calculatedGstAmount + Number(deliveryCharge);
    setGstAmount(calculatedGstAmount);
    setTotalAmount(calculatedTotalAmount);
  };

  const openModal = () => {
    calculateTotals();
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handlePriceSubmit = async () => {
    if (!seller) {
      toast.error("Seller information is not available.");
      return;
    }
    setLoading(true);
    try {
      const formattedPrices = Object.entries(modelPrices).map(
        ([modelName, price]) => ({
          modelname: modelName,
          price: Number(price),
        })
      );

      await axios.post(`${server}/quote3D/order/${order_id}/vendorPrice`, {
        modelPrices: formattedPrices,
        deliveryDate,
        deliveryCharge: Number(deliveryCharge),
        description,
        gstAmount,
        totalAmount,
        vendor_id: seller.id,
      });

      const response = await axios.get(`${server}/quote3D/order/${order_id}`);
      toast.success("Quotation Generated!");
      setLoading(false);
      setOrder(response.data);
      closeModal();
      setHasSubmitted(true); // Set the submission state to true
    } catch (error) {
      setLoading(false);
      console.error("Error adding vendor price:", error);
      toast.error("Failed to submit price.");
    }
  };

  if (!order) {
    return (
      <div className="flex items-center justify-center h-screen">
        <div className="relative w-12 h-12">
          <div className="h-12 w-12 border-t-4 border-b-4 border-gray-800 rounded-full animate-spin"></div>
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="h-6 w-6 rounded-full bg-gray-800 animate-ping"></div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 max-w-4xl mx-auto bg-white shadow-lg rounded-lg mt-10">
      <h2 className="text-3xl font-bold mb-6 text-gray-800">Order Details</h2>

      <div className="mb-3">
        <h4 className="text-lg font-semibold text-gray-800">Order ID:</h4>
        <p className="text-gray-600">{order.order_id}</p>
      </div>
      <div className="mb-3">
        <h4 className="text-lg font-semibold text-gray-800">Order Name:</h4>
        <p className="text-gray-600">{order.orderName}</p>
      </div>

      <div className="mb-6">
        <h4 className="text-lg font-semibold text-gray-800">Address:</h4>
        <p className="text-gray-600">{`${order.address.fullName}, ${
          order.address.address.address1
        }, ${order.address.address.address2 || ""}, ${order.address.city}, ${
          order.address.state
        }, ${order.address.zipCode}, ${order.address.country}`}</p>
        <p className="text-gray-600">Email: {order.address.email}</p>
        <p className="text-gray-600">Phone: {order.address.phone || "N/A"}</p>
      </div>

      <div className="mb-6">
        <h4 className="text-lg font-semibold text-gray-900">Items:</h4>
        {order.quotation.map((item, index) => (
          <div key={index} className="mb-4 p-4 bg-gray-200 rounded-lg">
            <p className="text-gray-700">
              <strong>Model Name:</strong> {item.modelname}
            </p>
            <div className="my-2">
              <p className="text-gray-700">
                <strong>Uploaded Files:</strong>
              </p>
              {item.files && item.files.length > 0 ? (
                item.files.map((filePath, fileIndex) => {
                  const fileName = filePath.split("/").pop().split("-")[0];
                  const viewName = filePath.split("/").pop();
                  return (
                    <div
                      key={fileIndex}
                      className="mt-2 flex items-center justify-between bg-gray-100 p-3 rounded-lg"
                    >
                      <div className=" flex items-center">
                      <FontAwesomeIcon
                        icon={faFile}
                        className="text-gray-500 mr-2"
                      />
                      <p className="text-black mr-2 truncate">{fileName}</p>
                      <a href={filePath} download>
                        <FontAwesomeIcon
                          icon={faDownload}
                          className="text-gray-500"
                        />
                      </a>
                      </div>
                      <div className="bg-gray-900 text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-300">
                        <Link to={'/vendor/order/view/'+viewName} className="text-white">
                        View Model
                        </Link>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-600">No files uploaded</p>
              )}
            </div>
            <p className="text-gray-700">
              <strong>Material:</strong> {item.material}
            </p>
            <p className="text-gray-700">
              <strong>Color:</strong> {item.color}
            </p>
            <p className="text-gray-700">
              <strong>Quantity:</strong> {item.quantity}
            </p>
            <p className="text-gray-700">
              <strong>Tolerance:</strong> {item.tolerance || "N/A"}
            </p>
            <p className="text-gray-700">
              <strong>Description:</strong> {item.description || "N/A"}
            </p>
            <p className="text-gray-700">
              <strong>Required Date:</strong>{" "}
              {new Date(item.requiredDate).toLocaleDateString()}
            </p>
            <p className="text-gray-700">
              <strong>Fill Density:</strong> {item.fillDensity}
            </p>
            {hasSubmitted ? (
              <p className="text-gray-700">
                <strong>Price for {item.modelname}:</strong> INR{" "}
                {modelPrices[item.modelname]}
              </p>
            ) : (
              <div className="mt-4">
                <label className="block text-gray-700 font-medium mb-2">
                  Price for {item.modelname}:
                </label>
                <input
                  type="text"
                  value={modelPrices[item.modelname] || ""}
                  onChange={(e) =>
                    handlePriceChange(item.modelname, e.target.value)
                  }
                  placeholder="Enter price"
                  className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                  disabled={hasSubmitted}
                />
              </div>
            )}
          </div>
        ))}
      </div>

      {hasSubmitted ? (
        <div className="mb-6 p-4 bg-gray-100 rounded-lg">
          <h4 className="text-lg font-semibold text-gray-800 mb-4">
            Submitted Quote Details:
          </h4>
          <p className="text-gray-800">
            <strong>Delivery Date:</strong>{" "}
            {new Date(deliveryDate).toLocaleDateString()}
          </p>
          <p className="text-gray-800">
            <strong>Delivery Charge:</strong> INR {deliveryCharge}
          </p>
          <p className="text-gray-800">
            <strong>GST Amount:</strong> INR {gstAmount.toFixed(2)}
          </p>
          <p className="text-gray-800">
            <strong>Total Amount:</strong> INR {totalAmount.toFixed(2)}
          </p>
          <p className="text-gray-800">
            <strong>Description:</strong> {description}
          </p>
        </div>
      ) : (
        <div className="mb-6 p-4 bg-gray-100 rounded-lg">
          <h4 className="text-lg font-semibold text-gray-800 mb-4">
            Add Your Quote Details{" "}
            <span className="text-gray-500">(All fields are compulsory!!)</span>
            :
          </h4>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Delivery Date:
              </label>
              <input
                type="date"
                value={deliveryDate}
                onChange={(e) => setDeliveryDate(e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                disabled={hasSubmitted}
              />
            </div>
            <div>
              <label className="block text-gray-700 font-medium mb-2">
                Delivery Charge:
              </label>
              <input
                type="text"
                value={deliveryCharge}
                onChange={(e) => setDeliveryCharge(e.target.value)}
                placeholder="Enter delivery charge"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                disabled={hasSubmitted}
              />
            </div>
            <div className="sm:col-span-2 lg:col-span-1">
              <label className="block text-gray-700 font-medium mb-2">
                Description:
              </label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter additional details"
                className="w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
                disabled={hasSubmitted}
              />
            </div>
          </div>
          {!hasSubmitted && (
            <button
              onClick={openModal}
              className="mt-4 bg-gray-900 text-white py-2 px-4 rounded-md hover:bg-gray-800 transition duration-300"
            >
              Submit Prices
            </button>
          )}
        </div>
      )}

      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Confirm Price Submission"
        className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-75"
        overlayClassName="fixed inset-0 bg-gray-900 bg-opacity-75"
      >
        <div className="bg-white p-8 rounded-lg shadow-lg max-w-md w-full">
          <h2 className="text-xl font-bold mb-2 text-gray-800">
            Quotation Submission
          </h2>
          <p className="mb-4 text-gray-600">
            Confirm the below prices before submitting.
          </p>
          <ul className="mb-2 text-gray-800">
            {Object.entries(modelPrices).map(([modelName, price]) => (
              <li key={modelName} className="mb-2">
                <strong>Model Name({modelName}):</strong> INR {price}
              </li>
            ))}
          </ul>
          <p className="mb-2 text-gray-800">
            <strong>Delivery Charge:</strong> INR {deliveryCharge}
          </p>
          <p className="mb-2 text-gray-800">
            <strong>GST Amount:</strong> INR {gstAmount.toFixed(2)}
          </p>
          <p className="mb-2 text-gray-800">
            <strong>Total Amount:</strong> INR {totalAmount.toFixed(2)}
          </p>
          <p className="mb-2 text-gray-800">
            <strong>Date of Delivery:</strong>{" "}
            {new Date(deliveryDate).toLocaleDateString()}
          </p>
          <div className="flex justify-end">
            <button
              onClick={closeModal}
              className="w-32 h-12 bg-gray-500 text-white py-2 px-4 rounded-md mr-2 hover:bg-gray-600 transition duration-300"
            >
              Cancel
            </button>
            <button
              onClick={handlePriceSubmit}
              className={`w-32 h-12 flex items-center justify-center rounded-xl text-white font-semibold ${
                loading
                  ? "bg-blue-400 cursor-not-allowed"
                  : "bg-blue-500 hover:bg-blue-600"
              }`}
              disabled={loading}
            >
              {loading && (
                <svg
                  className="animate-spin h-5 w-5 mr-3 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8v8h8a8 8 0 11-8-8z"
                  ></path>
                </svg>
              )}
              <span
                className={`${
                  loading ? "opacity-0" : "opacity-100"
                } transition-opacity`}
              >
                Confirm
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default QuotationAskDetailShop;
