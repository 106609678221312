import React from 'react';
import Header from '../components/Layout/Header';
import Footer from '../components/Layout/Footer';
import AboutUs from '../components/Layout/AboutUs';
import Loader from "../components/Layout/Loader.jsx";
import { useSelector } from "react-redux";

const AboutUsPage = () => {
    const { loading } = useSelector((state) => state.user);
    return (
        <div className='min-h-screen '>
            <Header/>
            <div className="container mx-auto py-8 ">
                {loading ? <Loader /> : <AboutUs />}
            </div>
            <Footer />
        </div>
    )
}

export default AboutUsPage;
