import React, { useState, useEffect, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { FaArrowLeft, FaArrowRight, FaStar, FaCopy } from "react-icons/fa";
import { server } from "../../server";
import styles from "../../styles/styles";

const ShopProfileData = ({ isOwner }) => {
  const [data, setData] = useState(null);
  const [reviews, setReviews] = useState([]);
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [copySuccess, setCopySuccess] = useState(false);
  const textAreaRef = useRef(null);

  useEffect(() => {
    // Fetch shop information
    axios
      .get(`${server}/shop/get-shop-info/${id}`)
      .then((res) => {
        setData(res.data.shop);
      })
      .catch((error) => {
        console.log(error);
      });

    // Fetch reviews for the shop's orders
    axios
      .get(`${server}/quote3D/shop/${id}/reviews`)
      .then((res) => {
        setReviews(res.data.reviews);
      })
      .catch((error) => {
        console.log(error);
      });

    // Set interval to move to the next review automatically
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
    }, 3000); // Change review every 3 seconds

    return () => clearInterval(interval);
  }, [id, reviews.length]);

  if (!data) {
    return <div>Loading...</div>;
  }

  const servicesArray = data.services || [];
  const servicesString = servicesArray.join(", ");

  const handlePrev = () => {
    setCurrentIndex(
      (prevIndex) => (prevIndex - 1 + reviews.length) % reviews.length
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % reviews.length);
  };

  // Helper function to generate star icons based on rating
  const renderRatingStars = (rating) => {
    const stars = [];
    for (let i = 0; i < rating; i++) {
      stars.push(<FaStar key={i} className="text-yellow-400" />);
    }
    return stars;
  };

  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess(true);
    setTimeout(() => setCopySuccess(false), 1500);
  };

  return (
    <div className="w-full p-6 bg-[#0e1421] rounded-lg">
      <div className="flex flex-col space-y-4">
        <div className="mb-5">
          <h5 className="text-lg font-semibold text-white mb-2">
            Services Offered
          </h5>
          <h4 className="text-white">{servicesString}</h4>
        </div>
        <h5 className="text-lg font-semibold text-white mb-2">
            {data.name} Public Profile
          </h5>
        <div className="bg-gray-800 rounded-lg p-6 shadow-lg mb-5">
          
          <div className="flex items-center mb-1">
            <a
              href={`https://bharatmaker.com/shop/preview/${data.id}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              https://bharatmaker.com/shop/preview/{data.id}
            </a>
            
          </div>
          <button
              className="text-white ml-2 cursor-pointer"
              onClick={copyToClipboard}
            >
              <FaCopy className="inline-block align-middle mr-1" />
              Copy Link
            </button>
        </div>

        {copySuccess && (
          <div className="text-green-500 text-sm">Link copied to clipboard</div>
        )}
        {/* Display reviews */}
        <h5 className="text-lg font-semibold text-white mb-2">
          Customers Reviews
        </h5>
        <div className="relative w-full h-48 overflow-hidden">
          {reviews.length > 0 ? (
            <div className="relative h-full flex items-center justify-start">
              {reviews.map((review, index) => (
                <div
                  key={index}
                  className={`absolute w-full h-full transition-transform duration-500 ${
                    index === currentIndex
                      ? "transform translate-x-0 opacity-100"
                      : "transform translate-x-full opacity-0"
                  } ${
                    index ===
                    (currentIndex - 1 + reviews.length) % reviews.length
                      ? "transform -translate-x-full opacity-100"
                      : ""
                  }`}
                >
                  <div className="bg-gray-800 p-4 rounded-lg h-full flex flex-col justify-center items-start w-1/2 relative">
                    <div className="absolute top-2 right-2 text-white">
                      {index + 1}/{reviews.length}
                    </div>
                    <p className="text-white mb-2">
                      User: {review.nameprofile}
                    </p>
                    <div className="flex items-center mb-2">
                      <p className="text-white mr-2">Rating: </p>
                      {renderRatingStars(review.rating)}
                    </div>
                    <p className="text-white mb-2">Review: {review.review}</p>
                    <div className="flex items-center mt-2">
                      <button
                        onClick={handlePrev}
                        className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-full mr-2"
                      >
                        <FaArrowLeft />
                      </button>
                      <button
                        onClick={handleNext}
                        className="bg-gray-700 hover:bg-gray-600 text-white p-2 rounded-full"
                      >
                        <FaArrowRight />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="text-white">No reviews yet.</p>
          )}
        </div>
        {isOwner && (
          <div className="mt-4 flex flex-col space-y-2">
            <Link to="/dashboard">
              <div
                className={`${styles.button} !rounded-md h-[42px] flex items-center justify-center`}
              >
                <span className="text-white">Go to Dashboard</span>
              </div>
            </Link>
          </div>
        )}
      </div>
      <textarea
        ref={textAreaRef}
        value={`https://bharatmaker.com/shop/preview/${data.id}`}
        className="absolute top-0 left-0 opacity-0 pointer-events-none"
        readOnly
      />
    </div>
  );
};

export default ShopProfileData;
