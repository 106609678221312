import React from 'react'
import AutoQuote from "../components/AutoQuote/AutoQuote.jsx"
const AutoQuotePage = () => {
  return (
    <div>
      {/* <AutoQuote /> */}
    </div>
  )
}

export default AutoQuotePage