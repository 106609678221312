import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {StlViewer} from 'react-stl-viewer';
// import {StlViewer} from 'stl-viewer-react'
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { server } from '../../server';
import { set } from 'mongoose';

const style = {
    top: 0,
    left: 0,
    width: '100vw',
    height: '80vh',
}

const StlVendor = ({fileName}) => {
  const [volume, setVolume] = useState(null);
  // const stlFileUrl = fileURL; // Replace with your STL file path

  // const { fileUrl } = useParams();
  // const [stlFileUrl, setFileUrl] = useState('https://bharatmaker2024.s3.ap-south-1.amazonaws.com/test-1721352690562-19972746.STL')

  // const stlFileUrl = fileUrl
  // const stlFileUrl = "https://bharatmaker2024.s3.ap-south-1.amazonaws.com/test-1721352690562-19972746.STL"
  // const stlFileUrl = "https://bharatmaker2024.s3.ap-south-1.amazonaws.com/" + fileUrl
  // const stlFileUrl = "http://localhost:3000/test.stl"

//   const [file, setFile] = useState('https://bharatmaker2024.s3.ap-south-1.amazonaws.com/')

  const file = 'https://bharatmaker2024.s3.ap-south-1.amazonaws.com/' + fileName

  useEffect(() => {
    const loader = new STLLoader();
    loader.load(file, (geometry) => {
      const volume = calculateSTLVolume(geometry);
      setVolume(volume);
    });
  }, [file]);

  const calculateSTLVolume = (geometry) => {
    let volume = 0;
    const position = geometry.attributes.position.array;
    const faces = position.length / 9;

    for (let i = 0; i < faces; i++) {
      const v0 = new THREE.Vector3(position[i * 9 + 0], position[i * 9 + 1], position[i * 9 + 2]);
      const v1 = new THREE.Vector3(position[i * 9 + 3], position[i * 9 + 4], position[i * 9 + 5]);
      const v2 = new THREE.Vector3(position[i * 9 + 6], position[i * 9 + 7], position[i * 9 + 8]);

      volume += signedVolumeOfTriangle(v0, v1, v2);
    }

    return Math.abs(volume);
  };

  const signedVolumeOfTriangle = (p1, p2, p3) => {
    return p1.dot(p2.cross(p3)) / 6.0;
  };

  const [obj, setObj] = useState(null)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)
  const [length, setLength] = useState(0)

  const onModel = (ev) => {
    setWidth(ev.width)
    setHeight(ev.height)
    setLength(ev.length)
  }

//   const onUpload = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const url = URL.createObjectURL(file);
//       setFile(url);
//     }
//   }

  return (
    <div>
      {/* <div style={{width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '3rem 0rem'}}>
        <form action="">
          <input type='file' style={{background: '#fff', color: '#000'}} placeholder='Upload File' onChange={onUpload} />
        </form>
      </div> */}
        <div className='w-full flex justify-center'>
            <div className='flex justify-center p-4 mx-4 w-1/2'>
            <StlViewer
      style={style}
      orbitControls
      shadows
      url={file}
      onFinishLoading={(ev) => onModel(ev)}
    />
            </div>
            <div className='flex justify-center items-center px-4 w-1/2'>
            <div>
            <div>
      Width: {width}mm
    </div>
    <div>
      Height: {height}mm
    </div>
    <div>
      length: {length}mm
    </div>
    {volume !== null && <p style={{color: '#fff'}}>Volume: {volume.toFixed(2)} cubic milimeter</p>}
            </div>
            </div>
      </div>
      {/* <div>
      Width: {obj.width}
      </div>
      <div>
        Height: {obj.height}
      </div>
      <div>
      Length: {obj.length}
      </div> */}
      {/* <StlViewer
            width={500}
            height={500}
            url={stlFileUrl}
            groundColor='rgb(255, 255, 255)'
            objectColor='rgb(50, 255, 255)'
            skyboxColor='rgb(255, 255, 255)'
            gridLineColor='rgb(0, 0, 0)'
            lightColor='rgb(255, 255, 255)'
            volume={setVolume}
          /> */}
    </div>
  );
};

export default StlVendor;