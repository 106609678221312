import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { server } from "../../server";
import { toast } from "react-toastify";

const CheckoutUpload = () => {
  const [files, setFiles] = useState([]);
  const navigate = useNavigate();

  const handleFileChange = (e) => {
    setFiles(e.target.files);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (files.length === 0) {
      toast.error('Please select at least one file.');
      return;
    }

    try {
      const formData = new FormData();

      // Append each file to FormData
      for (let i = 0; i < files.length; i++) {
        formData.append('files', files[i]);
      }

      const quotationData = JSON.parse(localStorage.getItem('quotationData'));
      const checkoutData = JSON.parse(localStorage.getItem('checkoutData'));
      const user_id = localStorage.getItem('user_id');
      
      const payload = {
        quotationData,
        checkoutData,
        user_id,
      };

      formData.append('payload', JSON.stringify(payload));
     
      await axios.post(`${server}/quote3D/order`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      localStorage.removeItem('quotationData');
      localStorage.removeItem('checkoutData');

      toast.success('Files uploaded successfully!');
      toast.success('Order Successfully Placed!');
      setFiles([]); // Clear the files after successful upload
      navigate('/'); // Navigate to homepage after submission
    } catch (error) {
      console.error('Error uploading files:', error.response.data.message);
      toast.error('Error uploading files: ' + error.response.data.message);
    }
  };

  return (
    <div className="max-w-lg mx-auto mt-11">
      <div className="bg-black rounded-lg shadow-md p-8 mt-11">
        <h2 className="text-2xl font-semibold mb-5">Upload Model Files</h2>
        <h5 className="font-semibold mb-11"><span className='text-gray-300'>(Upload each model file with the name of Model Name)</span></h5>
        <form onSubmit={handleSubmit}>
          <input type="file" onChange={handleFileChange} accept=".stl,.amf" className="mb-6" multiple />
          <button type="submit" className="bg-blue-500 text-white px-6 py-3 rounded-lg hover:bg-blue-600">Upload</button>
        </form>
      </div>
    </div>
  );
};

export default CheckoutUpload;
