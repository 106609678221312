import React from 'react'
import Header from "../components/Layout/Header.jsx";
import Forgot from "../components/Forgot/Forgot.jsx";
import Footer from "../components/Layout/Footer.jsx";
const ForgotPage = () => {
  return (
    <div className='w-full h-screen bg-[#111827]'>
      <Header/>
      <Forgot/>
      <Footer/>
    </div>
  )
}

export default ForgotPage