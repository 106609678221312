import React from "react";
import { Link } from "react-router-dom";
import logo from "../assests/logo.png";
import "./Header.css";

const Header = () => {
  return (
    <div>
      <Link to="/" className="heading">
        <img className="logo" src={logo} alt="Logo" />
        <span className="company-name">BharatMaker</span>
      </Link>
    </div>
  );
};

export default Header;