import React, { useEffect } from 'react'
import Login from "../components/Login/Login.jsx";
import { useNavigate } from 'react-router-dom';
import { useSelector } from "react-redux";
import Header from "../components/Layout/Header.jsx";
import Footer from "../components/Layout/Footer.jsx";
import Loader from "../components/Layout/Loader.jsx";
const LoginPage = () => {
  const navigate = useNavigate();
  // const { isAuthenticated, loading } = useSelector((state) => state.user);

  const loading = false

  // useEffect(() => {
  //   if (isAuthenticated) {
  //     navigate(`/profile`);
  //   }
  // }, [isAuthenticated, navigate]);

  return (
    <div>
      <Header />
      {loading ? (
        <Loader />
      ) : (
        <Login />
      )}
      <Footer />
    </div>
  );
}


export default LoginPage
