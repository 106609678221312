import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./ProductDetails.css";
import filamentWhite from "../assests/filament_white.jpg";
import filamentBlack from "../assests/filament_black.jpg";
import Header from "../Header/Header";

const ProductDetails = () => {
    const [selectedColor, setSelectedColor] = useState("white");
    // const [quantity, setQuantity] = useState(1);
  const [whiteQuantity, setWhiteQuantity] = useState(1);
  const [blackQuantity, setBlackQuantity] = useState(1);
    const [
        // formData
        , setFormData] = useState({});
    const navigate = useNavigate();
    const price = 599;
  
    const productImages = {
      white: filamentWhite,
      black: filamentBlack,
    };
  
    const technicalSpecifications = `
          Color :                   White/Black
          Weight of Filament :      500gm 
          Material:                 PLA(Poly Lactic Acid)         
          Diameter :                1.75mm          
          Best print temperature :  205-225 °C
          Base temperature :        0/65 °C
          Feed speed :              30-90 mm/s
         
      `;
  
    const features = `
      • PRECISION AND CONSISTENCY 
  
      • IMPROVED STRENGTH AND DURABILITY 
  
      • ENHANCED SURFACE FINISH
  
      • RELIABILITY AND COMPATIBILITY
       `;
    const reviews = `
          Aditya Agarwal 
          ⭐⭐⭐⭐⭐
          Very Good Product. Totally satisfied.
      `;
  
  const contact=`
      For any questions and enquiries, feel free to reach out to us:
      
      Email- maker.bharatt@gmail.com
      Phone- 8655864289
      `;
  
    const [tab, setTab] = useState("specifications");
    const tabContentRef = useRef(null);
  
    const handleColorChange = (color) => {
      setSelectedColor(color);
    };
  
  
  
  
  const handleWhiteQuantityChange = (event) => {
      const value = event.target.value;
      setWhiteQuantity(value === "" ? "" : Math.max(0, parseInt(value, 10)));
    };
  
    const handleBlackQuantityChange = (event) => {
      const value = event.target.value;
      setBlackQuantity(value === "" ? "" : Math.max(0, parseInt(value, 10)));
    };
  
    const scrollToTabContent = () => {
      if (tabContentRef.current) {
        tabContentRef.current.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    const handleReviewsClick = () => {
      setTab("reviews");
      scrollToTabContent();
    };
  
    const handleOrder = (event) => {
      event.preventDefault();
      const totalPrice = ((price * whiteQuantity) + (price * blackQuantity));
      const data = {
        price:price,
        totalPrice: totalPrice,
        blackQuantity: blackQuantity,
        whiteQuantity: whiteQuantity
      };
      setFormData(data);
      console.log("data",data);
      navigate("/pla-filament500/payment", { state: data });
    };
  
    const handleImageZoom = (event) => {
      const { clientX, clientY } = event;
      const { left, top, width, height } = event.target.getBoundingClientRect();
  
      const xPercent = ((clientX - left) / width) * 100;
      const yPercent = ((clientY - top) / height) * 100;
  
      event.target.style.transformOrigin = `${xPercent}% ${yPercent}%`;
      event.target.classList.add("zoomed");
    };
  
    const handleImageZoomOut = (event) => {
      event.target.style.transformOrigin = "center center";
      event.target.classList.remove("zoomed");
    };
  
    return (
      <div>
        <Header />
        <hr className="horizontal-line" />
        <div className="product-details">
          <div className="image-section">
            <div className="image-container">
              <img
                src={productImages[selectedColor]}
                alt={`${selectedColor} filament`}
                onMouseMove={handleImageZoom}
                onMouseLeave={handleImageZoomOut}
              />
            </div>
            <div className="color-selection">
          <span
            className={`color-option ${selectedColor === 'white' ? 'active' : ''}`}
            onClick={() => handleColorChange('white')}
          >
            White
          </span>
          <span
            className={`color-option ${selectedColor === 'black' ? 'active' : ''}`}
            onClick={() => handleColorChange('black')}
          >
            Black
          </span>
            </div>
          </div>
          <div className="details-section">
            <h1>
              PLA Filament{" "}
              {selectedColor.charAt(0).toUpperCase() + selectedColor.slice(1)} -
              500 GM
            </h1>
            <p >
              <b>
                <i className="price">₹ {price} </i>
                
              </b>
              <i>(inluding GST)</i>
            </p>
            <p></p>
            <p onClick={handleReviewsClick} className="review">
              ⭐⭐⭐⭐⭐
              
              (1 Reviews)
            </p>
  
            <div>
              <p>
                {" "}
                <b>
                  {" "}
                  <i>Description: </i>
                </b>
                <br />
                This filament is made of poly lactic acid. It is suitable for low
                temperature printing and can be used in any project that requires
                an aesthetic finishing. I assure you that our filament equipment
                embodies versatility, precision, and eco-friendliness, making it
                perfect for a wide array of 3D printing needs. Whether you're a
                seasoned professional or an enthusiastic hobbyist, our products
                promise cost-effective solutions with easy handling and
                compatibility across various applications. Expect nothing less
                than top-notch performance and reliability for your creative
                projects.
              </p>
            </div>
            <br />
           
            <div className="color-quantity">
            <div className="color-quantity-white">
              <label>
                <b>
                  <i>White Quantity: </i>
                </b>
              </label>
              <input
                type="number"
                min="0"
                value={whiteQuantity}
                onChange={handleWhiteQuantityChange}
                className="quantity-input"
              />
            </div>
            <div className="color-quantity-black">
              <label>
                <b>
                  <i>Black Quantity: </i>
                </b>
              </label>
              <input
                type="number"
                min="0"
                value={blackQuantity}
                onChange={handleBlackQuantityChange}
                className="quantity-input"
              />
            </div>
          </div>
  
      <br />
      
            <p >
              <b>
                <i className="total-price">Amount: ₹ {(price * whiteQuantity) + (price * blackQuantity)}  </i>
              </b>{" "}
              {"  "}<i>(inluding GST)</i>
            </p>
          
          
            <button onClick={handleOrder} className="order-button">
              Order
            </button>
          </div>
          </div>
        <div>
        <div className="tabs">
    <div
      onClick={() => {
        setTab("specifications");
        // scrollToTabContent();
      }}
      className={`tab ${tab === "specifications" ? "active" : ""}`}
    >
      Technical Specifications
    </div>
    <div
      onClick={() => {
        setTab("features");
        // scrollToTabContent();
      }}
      className={`tab ${tab === "features" ? "active" : ""}`}
    >
      Features
    </div>
    <div
      onClick={() => {
        setTab("reviews");
        scrollToTabContent();
      }}
      className={`tab ${tab === "reviews" ? "active" : ""}`}
    >
      Customer Reviews(1)
    </div>
    <div
      onClick={() => {
        setTab("contact");
        // scrollToTabContent();
      }}
      className={`tab ${tab === "contact" ? "active" : ""}`}
    >
      Contact Us
    </div>
  </div>
  <hr className="hr" />
  <div ref={tabContentRef} className="tab-content">
    {tab === "specifications" && <pre>{technicalSpecifications}</pre>}
    {tab === "features" && <pre>{features}</pre>}
    {tab === "reviews" && <pre>{reviews}</pre>}
    {tab === "contact" && <pre className="contact"><b>{contact}</b></pre>}
  </div>
  </div>
      </div>
    );
  };

export default ProductDetails;
