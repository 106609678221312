import React, { useState, useEffect } from 'react';
import Header from "../components/Layout/Header.jsx";
import HeroSection from "../components/Layout/HeroSection.jsx";
import Feature from "../components/Layout/Feature.jsx";
import Footer from "../components/Layout/Footer.jsx";
import Loader from "../components/Layout/Loader.jsx";

const HomePage = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the timeout duration as needed

    return () => clearTimeout(timer);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      <Header />
      <HeroSection />
      <Feature />
      <Footer />
    </div>
  );
};

export default HomePage
