import React from "react";
import { FiShoppingBag } from "react-icons/fi";
import { BiMessageSquareDetail } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../Images/logo.png";

const DashboardHeader = () => {
  const { seller } = useSelector((state) => state.seller);
  const firstName = seller?.name.split(" ")[0];

  return (
    <div className="w-full h-[80px] bg-black shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div className="flex items-center flex-shrink-0">
        <Link to="/dashboard">
          <img className="h-10 w-10 mr-2" src={logo} alt="Logo" />
        </Link>
        <Link to="/dashboard">
          <span className="text-2xl bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text">
            BharatMaker
          </span>
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4 space-x-6">
          <div className="relative group">
            <Link to="/dashboard-orders">
              <FiShoppingBag
                color="#FFF"
                size={30}
                className="transition-transform transform group-hover:scale-110"
              />
              <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-blue-500 text-white text-xs rounded py-1 px-2">
                Orders
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 bottom-full h-2 w-2 text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 24l12-12H0z" />
                </svg>
              </div>
            </Link>
          </div>
          <div className="relative group">
            <Link to="/dashboard-messages">
              <BiMessageSquareDetail
                color="#FFF"
                size={30}
                className="transition-transform transform group-hover:scale-110"
              />
              <div className="absolute left-1/2 transform -translate-x-1/2 top-full mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-300 bg-blue-500 text-white text-xs rounded py-1 px-2">
                Inbox
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 bottom-full h-2 w-2 text-gray-700"
                  fill="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path d="M12 24l12-12H0z" />
                </svg>
              </div>
            </Link>
          </div>
          <Link
            to={`/shop/${seller.id}`}
            className="py-2 px-4 bg-gradient-to-r from-blue-500 to-blue-800 text-white rounded-md transition-transform transform hover:scale-105"
          >
            Shop Preview, {firstName}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DashboardHeader;
