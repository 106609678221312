import React from 'react'
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar.jsx";
import AllOrders from "../components/Admin/AllOrders.jsx";
const AdminDashboardOrders = () => {
 
    return (
        <div>
          <AdminHeader />
          <div className="flex">
            <div className="w-[70px] 800px:w-[280px]">
              <AdminSideBar active={4} />
            </div>
            <div className="flex-grow">
              <AllOrders />
            </div>
          </div>
        </div>
      );
 
}

export default AdminDashboardOrders