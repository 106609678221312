import React, { useEffect } from 'react'
import QuotationAskDetail from "../components/Quotation/QuotationAskDetail.jsx";
import StlViewers from '../components/AutoQuote/StlViewer.jsx';
import DashboardHeader from '../components/Shop/Layout/DashboardHeader.jsx';
import { useParams } from 'react-router-dom';
import Header from '../components/Layout/Header.jsx';
import Loader from '../components/Layout/Loader.jsx';
import { useSelector } from 'react-redux';
import StlVendor from '../components/AutoQuote/StlVendor.jsx';
const StlViewerVendor = () => {
    const {fileName} = useParams()

    // useEffect(() => {
    //     console.log(fileUrl)
    // })

  const { loading } = useSelector((state) => state.user);

  return (
    <div>
      <DashboardHeader />
      {loading ? (
        <Loader />
      ) : (
        <>
      
      <div>
        <StlVendor fileName={fileName} />
      </div>
      </>
      )}
    </div>
  )
}

export default StlViewerVendor