import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "../../styles/styles";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";
import { loadSeller } from "../../redux/actions/user";
import { State, City } from "country-state-city"; // Import only the used parts of the package

const ShopSettings = () => {
  const { seller } = useSelector((state) => state.seller);
  const [name, setName] = useState(seller && seller.name);
  const [description, setDescription] = useState(seller && seller.description ? seller.description : "");
  const [address, setAddress] = useState(seller && seller.address);
  const [zipCode, setZipcode] = useState(seller && seller.zipCode);
  const [phoneNumber, setPhoneNumber] = useState(seller && seller.phoneNumber);
  const [state, setState] = useState(seller && seller.state);
  const [city, setCity] = useState(seller && seller.city);
  const [gstNumber, setGstNumber] = useState(seller && seller.gstNumber);
  const [email, setEmail] = useState(seller && seller.email);
  const [cities, setCities] = useState([]); // State to hold cities
  const dispatch = useDispatch();
  

  useEffect(() => {
    if (state) {
      const selectedState = State.getStatesOfCountry("IN").find((s) => s.name === state);
      if (selectedState) {
        setCities(City.getCitiesOfState("IN", selectedState.isoCode));
      }
    }
  }, [state]);

  const updateHandler = async (e) => {
    e.preventDefault();

    await axios
      .put(
        `${server}/shop/update-seller-info`,
        {
          name,
          address,
          zipCode,
          phoneNumber,
          description,
          state,
          city,
          gstNumber,
          email,
        },
        { withCredentials: true }
      )
      .then((res) => {
        toast.success("Shop info updated successfully!");
        toast.success("Registered Successfully!!");
        dispatch(loadSeller());
      })
      .catch((error) => {
        toast.error(error.response.data.message);
      });
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center">
      <div className="flex w-full 800px:w-[80%] flex-col justify-center my-5">
      <h2 className="text-2xl font-extrabold text-gray-400 mb-4 text-center">
          Fill all the fields below to start receiving orders!!
        </h2>
        {/* shop info */}
        <form
          className="flex flex-col items-center"
          onSubmit={updateHandler}
        >
          <div className="grid grid-cols-1 800px:grid-cols-2 gap-4 w-full 800px:w-[80%]">
            <div className="flex flex-col">
              <label className="block pb-2">Shop Name <span className="text-red-500">*</span></label>
              <input
                type="name"
                placeholder={`${seller.name}`}
                value={name}
                onChange={(e) => setName(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">Shop Description</label>
              <input
                type="name"
                placeholder={`${seller?.description ? seller.description : "Enter your shop description"}`}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              />
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">Shop Email <span className="text-red-500">*</span></label>
              <input
                type="name"
                placeholder={`${seller.email}`}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">Phone Number (+91) <span className="text-red-500">*</span></label>
              <input
                type="tel"
                placeholder={`${seller?.phoneNumber ? seller.phoneNumber : "Enter your Phone Number"}`}
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
                pattern="[0-9]{10}"
              />
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">Address <span className="text-red-500">*</span></label>
              <input
                type="name"
                placeholder={`${seller?.address ? seller.address : "Enter your Address"}`}
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">State <span className="text-red-500">*</span></label>
              <select
                name="state"
                required
                value={state}
                onChange={(e) => setState(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                style={{ color: "white" }}
              >
                <option value="">Select State</option>
                {State.getStatesOfCountry("IN").map((state) => (
                  <option key={state.isoCode} value={state.name}>
                    {state.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">City <span className="text-red-500">*</span></label>
              <select
                name="city"
                required
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                style={{ color: "white" }}
              >
                <option value="">Select City</option>
                {cities.map((city) => (
                  <option key={city.name} value={city.name}>
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">Shop Zip Code <span className="text-red-500">*</span></label>
              <input
                type="number"
                placeholder={`${seller?.zipCode ? seller.zipCode : "Enter your ZipCode"}`}
                value={zipCode}
                onChange={(e) => setZipcode(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>
            <div className="flex flex-col">
              <label className="block pb-2">GST Number <span className="text-red-500">*</span></label>
              <input
                type="name"
                placeholder={`${seller?.gstNumber ? seller.gstNumber : "Enter your GST Number"}`}
                value={gstNumber}
                onChange={(e) => setGstNumber(e.target.value)}
                className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
                required
              />
            </div>
          </div>
          <div className="w-[100%] flex items-center flex-col 800px:w-[50%] mt-5">
            <input
              type="submit"
              value="Update Shop"
              className={`${styles.input} !w-[95%] mb-4 800px:mb-0`}
              required
              readOnly
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ShopSettings;
