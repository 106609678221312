import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../Images/logo.png";
import axios from "axios";
import { server } from "../../server";
import { toast } from "react-toastify";
import { AiOutlineLogin } from "react-icons/ai";

const AdminHeader = () => {
  const { user } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const firstName = user?.name.split(" ")[0];

  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };

  return (
    <div className="w-full h-[80px] bg-black shadow sticky top-0 left-0 z-30 flex items-center justify-between px-4">
      <div className="flex items-center flex-shrink-0">
        <Link to="/">
          <img className="h-10 w-10 mr-2" src={logo} alt="Logo" />
        </Link>
        <Link to="/">
          <span className="text-2xl bg-gradient-to-r from-blue-400 to-blue-500 text-transparent bg-clip-text">
            BharatMaker
          </span>
        </Link>
      </div>
      <div className="flex items-center">
        <div className="flex items-center mr-4 space-x-6">
          <Link
            className="py-2 px-4 bg-gradient-to-r from-blue-500 to-blue-800 text-white rounded-md transition-transform transform hover:scale-105"
          >
            Admin, {firstName}
          </Link>
          
              <button
                onClick={logoutHandler}
                className="flex items-center py-2 px-4 bg-gradient-to-r from-red-500 to-red-800 text-white rounded-md transition-transform transform hover:scale-105"
              >
                <AiOutlineLogin size={20} className="mr-2" />
                Logout
              </button>
            
        </div>
      </div>
    </div>
  );
};

export default AdminHeader;
