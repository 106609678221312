import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server"; 

const ReviewForm = ({ order_id }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const [submittedReview, setSubmittedReview] = useState(null); // Track submitted review

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.post(`${server}/quote3D/order/${order_id}/review`, {
        rating,
        review,
      });

      if (response.status === 200) {
        toast.success("Review submitted successfully!");
        setSubmittedReview({ rating, review }); // Store submitted review
        setRating(0);
        setReview("");
      } else {
        toast.error("Failed to submit review!");
      }
    } catch (error) {
      console.error("Error submitting review:", error);
      toast.error("All fields are required!");
    }
  };

  // Load submitted review details on component mount
  useEffect(() => {
    const fetchReview = async () => {
      try {
        const response = await axios.get(`${server}/quote3D/order/${order_id}/review`);
        if (response.status === 200) {
          const { rating, review } = response.data.review;
          setSubmittedReview({ rating, review });
        }
      } catch (error) {
        console.error("Error fetching review:", error);
      }
    };

    fetchReview();
  }, [order_id]);

  if (submittedReview) {
    return (
      <div className="bg-black p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold mb-4">Review Submitted</h3>
        <p className="text-white mb-2">Rating: {submittedReview.rating}</p>
        <p className="text-white mb-2">Review: {submittedReview.review}</p>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="bg-black p-6 rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-4">Leave a Review</h3>
      <div className="mb-4">
        <label className="block text-white">Rating:</label>
        <div className="flex">
          {[1, 2, 3, 4, 5].map((star) => (
            <button
              key={star}
              type="button"
              className={`text-2xl ${star <= rating ? 'text-yellow-300' : 'text-gray-300'} focus:outline-none`}
              onClick={() => setRating(star)}
            >
              &#9733; {/* Unicode star character */}
            </button>
          ))}
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-white">Review:</label>
        <textarea
          value={review}
          onChange={(e) => setReview(e.target.value)}
          className="w-full border border-gray-300 rounded-lg p-2"
          rows="4"
        ></textarea>
      </div>
      <button
        type="submit"
        className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600"
      >
        Submit Review
      </button>
    </form>
  );
};

export default ReviewForm;
