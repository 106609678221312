import React from 'react'
import Header from '../components/Layout/Header'
import CheckoutSteps from "../components/Checkout/CheckoutSteps";
import CheckoutUpload from "../components/Checkout/CheckoutUpload.jsx";
import Footer from '../components/Layout/Footer';

const CheckoutUploadPage = () => {
  return (
    <div>
        <Header />
        <br />
        <br />
        <CheckoutSteps active={2} />
        <CheckoutUpload />
        <br />
        <br />
        <Footer />
    </div>
  )
}

export default CheckoutUploadPage