import React from 'react';
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar.jsx";
import AdminBlog from "../components/Admin/AdminBlog.jsx";

const AdminBlogPage = () => {
  return (
    <div>
      <AdminHeader />
      <div className="flex">
        <div className="w-[70px] 800px:w-[280px]">
          <AdminSideBar active={6} />
        </div>
        <div className="flex-grow">
          <AdminBlog/>
        </div>
      </div>
    </div>
  );
};

export default AdminBlogPage;