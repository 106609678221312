import React from 'react'
import QuotationAskDetailShop from "../components/Quotation/QuotationAskDetailShop.jsx";
const QuotationAskDetailShopPage = () => {
  return (
    <div>
      <QuotationAskDetailShop/>
    </div>
  )
}

export default QuotationAskDetailShopPage
