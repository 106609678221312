import React from "react";
import Lottie from "react-lottie";
import animationData from "../../Assests/animations/24151-ecommerce-animation.json";

const Loader = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div className="w-full h-screen flex flex-col items-center justify-center">
      <Lottie options={defaultOptions} width={300} height={300} />
      <div className="flex items-center mt-4">
        <div className="w-6 h-6 border-4 border-blue-500 border-t-transparent border-solid rounded-full animate-spin mr-2"></div>
        <div className="hero-heading text-blue-500 text-2xl animate-pulse">Loading</div>
      </div>
    </div>
  );
};

export default Loader;
