import React, { useState, 
  useEffect 
} from "react";
import { AiOutlineEye, AiOutlineEyeInvisible,
  //  AiOutlineDown 
  } from "react-icons/ai";
import styles from "../../styles/styles";
import { Link } from "react-router-dom";
import axios from "axios"; // Import axios
import { server } from "../../server";
import { toast } from "react-toastify";
// import { State, City } from "country-state-city"; // Import only the used parts of the package

const ShopCreate = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [name, setName] = useState("");
  // const [phoneNumber, setPhoneNumber] = useState("");
  // const [address, setAddress] = useState("");
  // const [state, setState] = useState("");
  // const [city, setCity] = useState("");
  // const [zipCode, setZipCode] = useState("");
  // const [gstNumber, setGSTNumber] = useState("");
  const [services, setServices] = useState([]);
  // const [cities, setCities] = useState([]); // State to hold cities
  const [loading, setLoading] = useState(false); 
  // useEffect(() => {
  //   if (state) {
  //     const selectedState = State.getStatesOfCountry("IN").find(
  //       (s) => s.name === state
  //     );
  //     if (selectedState) {
  //       setCities(City.getCitiesOfState("IN", selectedState.isoCode));
  //     }
  //   }
  // }, [state]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleServiceChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setServices([...services, value]);
    } else {
      setServices(services.filter((service) => service !== value));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const newForm = new FormData();
    newForm.append("name", name);
    newForm.append("email", email);
    newForm.append("password", password);
    // newForm.append("phoneNumber", parseInt(phoneNumber, 10));
    // newForm.append("address", address);
    // newForm.append("state", state);
    // newForm.append("city", city);
    // newForm.append("zipCode", parseInt(zipCode, 10));
    // newForm.append("gstNumber", gstNumber);
    newForm.append("services", JSON.stringify(services)); // Send as JSON string

    axios
      .post(`${server}/shop/create-shop`, newForm, config)
      .then((res) => {
        toast.success(res.data.message);
        setLoading(false);
        setName("");
        setEmail("");
        setPassword("");
        // setPhoneNumber("");
        // setAddress("");
        // setState("");
        // setCity("");
        // setZipCode("");
        // setGSTNumber("");
        setServices([]);
      })
      .catch((error) => {
        toast.error(error.response.data.message);
        setLoading(false);
      });
  };

  const availableServices = ["3D Printing"];

  return (
    <div className="min-h-screen flex flex-col">
      {/* Gray background up to certain height */}
      <div className="bg-[#111827] py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* Centered content */}
          <h2 className="text-center text-3xl font-extrabold text-gray-50">
            Register as a Vendor
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-[35rem]">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <form className="space-y-6" onSubmit={handleSubmit}>
              <div>
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-black"
                >
                  Company Name
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="name"
                    required
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }} // Set text color to black
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-black"
                >
                  Email Address
                </label>
                <div className="mt-1">
                  <input
                    type="email"
                    name="email"
                    autoComplete="email"
                    required
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }} // Set text color to black
                  />
                </div>
              </div>
              {/* <div>
                <label
                  htmlFor="phone-number"
                  className="block text-sm font-medium text-black"
                >
                  Phone Number
                </label>
                <div className="mt-1">
                  <input
                    type="tel" // Change type to "tel" for phone number input
                    name="phone-number"
                    autoComplete="tel" // Enable autocomplete for phone numbers
                    pattern="[0-9]{10}" // Set pattern to accept only 10 digits
                    required
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }} // Set text color to black
                  />
                </div>
              </div> */}

              {/* <div>
                <label
                  htmlFor="address"
                  className="block text-sm font-medium text-black"
                >
                  Address
                </label>
                <div className="mt-1">
                  <input
                    type="text"
                    name="address"
                    required
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }} // Set text color to black
                  />
                </div>
              </div> */}

              {/* <div className="relative">
                <label
                  htmlFor="state"
                  className="block text-sm font-medium text-black"
                >
                  State
                </label>
                <div className="mt-1 relative">
                  <select
                    name="state"
                    required
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }}
                  >
                    <option value="">Select State</option>
                    {State.getStatesOfCountry("IN").map((state) => (
                      <option key={state.isoCode} value={state.name}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  <AiOutlineDown className="absolute right-2 top-2 pointer-events-none" size={20} />
                </div>
              </div> */}

              {/* <div className="relative">
                <label
                  htmlFor="city"
                  className="block text-sm font-medium text-black"
                >
                  City
                </label>
                <div className="mt-1 relative">
                  <select
                    name="city"
                    required
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }}
                  >
                    <option value="">Select City</option>
                    {cities.map((city) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </select>
                  <AiOutlineDown className="absolute right-2 top-2 pointer-events-none" size={20} />
                </div>
              </div> */}

              {/* <div>
                <label
                  htmlFor="zip-code"
                  className="block text-sm font-medium text-black"
                >
                  Zip Code
                </label>
                <div className="mt-1">
                  <input
                    type="text" // Change type to "text" for zip code input
                    name="zip-code"
                    required
                    value={zipCode}
                    onChange={(e) => setZipCode(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }} // Set text color to black
                  />
                </div>
              </div> */}

              {/* <div>
                <label
                  htmlFor="gst-number"
                  className="block text-sm font-medium text-black"
                >
                  GST Number
                </label>
                <div className="mt-1">
                  <input
                    type="text" // Change type to "text" for GST number input
                    name="gst-number" // Set name attribute to "gst-number"
                    required
                    value={gstNumber}
                    onChange={(e) => setGSTNumber(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black" }} // Set text color to black
                  />
                </div>
              </div> */}

              <div>
                <label
                  htmlFor="services"
                  className="block text-sm font-medium text-black"
                >
                  Services Provided
                </label>
                <div className="mt-1">
                  {availableServices.map((service) => (
                    <div key={service} className="flex items-center">
                      <input
                        type="checkbox"
                        name="services"
                        required
                        value={service}
                        checked={services.includes(service)}
                        onChange={handleServiceChange}
                        className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded invert"
                      />
                      <label
                        htmlFor="services"
                        className="ml-2 block text-sm text-black"
                      >
                        {service}
                      </label>
                    </div>
                  ))}
                </div>
              </div>

              <div>
                <label
                  htmlFor="password"
                  className="block text-sm font-medium text-black"
                >
                  Password
                </label>
                <div className="mt-1 relative">
                  <input
                    type={visible ? "text" : "password"}
                    name="password"
                    autoComplete="current-password"
                    required
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm bg-white"
                    style={{ color: "black", placeholder: "white" }}
                  />

                  {visible ? (
                    <AiOutlineEye
                      className="absolute right-2 top-2 cursor-pointer text-black" // Add text-black class
                      size={25}
                      onClick={() => setVisible(false)}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      className="absolute right-2 top-2 cursor-pointer text-black" // Add text-black class
                      size={25}
                      onClick={() => setVisible(true)}
                    />
                  )}
                </div>
              </div>

              <div>
              <button
                  type="submit"
                  className="group relative w-full h-[40px] flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700"
                  disabled={loading} // Disable button when loading
                >
                  {loading ? (
                    <div className="flex items-center">
                      <div className="w-4 h-4 border-t-2 border-b-2 border-white rounded-full animate-spin mr-2"></div>
                      Submitting...
                    </div>
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
              <div className={`${styles.noramlFlex} w-full`}>
                <h4 className="text-black">Already have an account?</h4>
                <Link to="/shop-login" className="text-blue-600 pl-2">
                  Sign In
                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShopCreate;
