import React from "react";
import {useNavigate } from "react-router-dom";
import { RxPerson } from "react-icons/rx";
import { HiOutlineShoppingBag } from "react-icons/hi";
import {MdOutlineAdminPanelSettings} from "react-icons/md";
import {AiOutlineLogin,AiOutlineMessage } from "react-icons/ai";
import { RiLockPasswordLine } from "react-icons/ri";
import { TbAddressBook } from "react-icons/tb";
import axios from "axios";
import { Link} from "react-router-dom";
import { server } from "../../server";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { MdBorderColor } from "react-icons/md";
import {BsShop} from "react-icons/bs"
const ProfileSideBar = ({ setActive, active }) => {
  const navigate = useNavigate();
  const {user} = useSelector((state) => state.user);
  const logoutHandler = () => {
    axios
      .get(`${server}/user/logout`, { withCredentials: true })
      .then((res) => {
        toast.success(res.data.message);
        window.location.reload(true);
        navigate("/");
      })
      .catch((error) => {
        console.log(error.response.data.message);
      });
  };
  return (
    <div className="w-full bg-[#000000] shadow-sm rounded-[10px] p-4 pt-8">
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(2)}
      >
        <RxPerson size={20} color={active === 2 ? "#3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 2 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Profile
        </span>
      </div>
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(3)}
      >
        <HiOutlineShoppingBag size={20} color={active === 3 ? "3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 3 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Completed Orders
        </span>
      </div>
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(1)
          //  || navigate("/inbox")
        }
      >
        <AiOutlineMessage size={20} color={active === 1 ? "3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 1 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Ongoing Orders
        </span>
      </div>
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(8)
          //  || navigate("/inbox")
        }
      >
        <BsShop size={20} color={`${active === 8 ? "#3eb7eb" : "#FFF"}`} />
        <span
          className={`pl-3 ${
            active === 8 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          All Sellers
        </span>
      </div>
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(4)}
      >
        <RiLockPasswordLine size={20} color={active === 4 ? "#3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 4 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Change Password
        </span>
      </div>

      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(5)}
      >
        <TbAddressBook size={20} color={active === 5 ? "#3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 5 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Address
        </span>
      </div>
      {user && user?.role === "Admin" && (
        <Link to="/admin/dashboard">
          <div
            className="flex items-center cursor-pointer w-full mb-8"
            onClick={() => setActive(6)}
          >
            <MdOutlineAdminPanelSettings
              size={20}
              color={active === 6 ? "#3eb7eb" : ""}
            />
            <span
              className={`pl-3 ${
                active === 6 ? "text-[#3eb7eb]" : ""
              } 800px:block hidden`}
            >
              Admin
            </span>
          </div>
        </Link>
      )}
      <Link to="/quote-3D">
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={() => setActive(7)}
      >
        <MdBorderColor  size={20} color={active === 7 ? "#3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 7 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Get Quote
        </span>
      </div>
      </Link>
      <div
        className="flex items-center cursor-pointer w-full mb-8"
        onClick={logoutHandler}
      >
        <AiOutlineLogin size={20} color={active === 6 ? "#3eb7eb" : ""} />
        <span
          className={`pl-3 ${
            active === 6 ? "text-[#3eb7eb]" : ""
          } 800px:block hidden`}
        >
          Log Out
        </span>
      </div>

    </div>
  );
};

export default ProfileSideBar;
