import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  LoginPage,
  SignupPage,
  ForgotPage,
  ForgotShopPage,
  HomePage,
  ActivationPage,
  ShopCreatePage,
  SellerActivationPage,
  ShopLoginPage,
  QuotationPage3D,
  QuotationPageLaser,
  ProfilePage,
  CheckoutPage,
  CheckoutUploadPage,
  QuotationAskDetailPage,
  QuotationAskDetailShopPage,
  BlogPage,
  BlogDetailPage,
  AboutUsPage,
  ContactPage,
  ServicePage,
  AutoQuotePage,

  // PLA Filament
  ProductDetails,
  PaymentDetails,
  RequestReceivedPage
} from "./routes/Routes.js";
import {
  ShopDashboardPage,
  ShopSettingsPage,
  ShopMessagePage,
  ShopOrdersPage,
  ShopPreviewPage,
  ShopPasswordPage,
  StlViewerVendor
} from "./routes/ShopRoutes.js";
import {
  AdminDashboardPage,
  AdminDashboardUsers,
  AdminDashboardSellers,
  AdminDashboardOrders,
  AdminDashboardMessages,
  AdminBlogPage,
} from "./routes/AdminRoutes.js";
// import { server } from "./server.js";
// import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import {toast} from "react-toastify";
import Store from "./redux/store.js";
// import { useSelector } from "react-redux";
import { loadUser, loadSeller } from "./redux/actions/user.js";
import ProtectedRoute from "./routes/ProtectedRoute.js";
import ProtectedAdminRoute from "./routes/ProtectedAdminRoute.js";
import SellerProtectedRoute from "./routes/SellerProtectedRoute.js";
import { ShopHomePage } from "./ShopRoutes.js";
import StlViewerPage from "./pages/StlViewerPage.jsx";
const App = () => {
  // const { isAuthenticated } = useSelector((state) => state.user);
  // const { isSeller} = useSelector((state) => state.seller);
  useEffect(() => {
    Store.dispatch(loadUser());
    Store.dispatch(loadSeller());

    // axios
    //   .get(`${server}/user/getuser`, { withCredentials: true })
    //   .then((res) => {
    //     toast.success(res.data.message);
    //   })
    //   .catch((err) => {
    //     toast.error(err.response.data.message);
    //   });
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/sign-up" element={<SignupPage />} />
        <Route path="/forget-password" element={<ForgotPage />} />
        <Route path="/forget-password-shop" element={<ForgotShopPage />} />
        <Route path="/about" element={<AboutUsPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/services" element={<ServicePage />} />
        <Route
          path="/activation/:activation_token"
          element={<ActivationPage />}
        />
        <Route
          path="/seller/activation/:activation_token"
          element={<SellerActivationPage />}
        />

        {/* Shop route */}
        <Route path="/shop-create" element={<ShopCreatePage />} />
        <Route path="/shop-login" element={<ShopLoginPage />} />
        <Route
          path="/shop/:id"
          element={
            <SellerProtectedRoute>
              <ShopHomePage />
            </SellerProtectedRoute>
          }
        />
        <Route path="/shop/preview/:id" element={<ShopPreviewPage />} />
        <Route
          path="/settings"
          element={
            <SellerProtectedRoute>
              <ShopSettingsPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <SellerProtectedRoute>
              <ShopDashboardPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-messages"
          element={
            <SellerProtectedRoute>
              <ShopMessagePage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-orders"
          element={
            <SellerProtectedRoute>
              <ShopOrdersPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/dashboard-password"
          element={
            <SellerProtectedRoute>
              <ShopPasswordPage />
            </SellerProtectedRoute>
          }
        />

        <Route
          path="/req-received"
          element={
            <RequestReceivedPage />
          }
        />
        <Route
          path="/quote-3D"
          element={
            <ProtectedRoute>
              <QuotationPage3D />
            </ProtectedRoute>
          }
        />
        <Route
          path="/auto-quote"
          element={
            <ProtectedRoute>
              <AutoQuotePage />
            </ProtectedRoute>
          }
        />
        <Route path="/quote-laser" element={<QuotationPageLaser />} />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <ProfilePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout"
          element={
            <ProtectedRoute>
              <CheckoutPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/checkout-fileupload"
          element={
            <ProtectedRoute>
              <CheckoutUploadPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/user/order/:order_id"
          element={
            <ProtectedRoute>
              <QuotationAskDetailPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/onlinestlviewer/"
          element={
            // <ProtectedRoute>
              <StlViewerPage />
            // {/* </ProtectedRoute> */}
          }
        />
        <Route
          path="/vendor/order/:order_id"
          element={
            <SellerProtectedRoute>
              <QuotationAskDetailShopPage />
            </SellerProtectedRoute>
          }
        />
        <Route
          path="/vendor/order/view/:fileName"
          element={
            <SellerProtectedRoute>
              <StlViewerVendor />
            </SellerProtectedRoute>
          }
        />
        {/* Admin Routes */}
        <Route
          path="/admin/dashboard"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardPage />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-users"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardUsers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-sellers"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardSellers />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-orders"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardOrders />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-messages"
          element={
            <ProtectedAdminRoute>
              <AdminDashboardMessages />
            </ProtectedAdminRoute>
          }
        />
        <Route
          path="/admin-blogs"
          element={
            <ProtectedAdminRoute>
              <AdminBlogPage />
            </ProtectedAdminRoute>
          }
        />
        <Route path="/blogs" element={<BlogPage />} />
        <Route path="/blogs/:id" element={<BlogDetailPage />} />


        {/* PLA Filament Routes */}
        <Route path="/pla-filament500" element={<ProductDetails/>} />
        <Route path="/pla-filament500/payment" element={<PaymentDetails/>} />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </BrowserRouter>
  );
};

export default App;
