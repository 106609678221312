import React from 'react'
import AdminHeader from "../components/Layout/AdminHeader";
import AdminSideBar from "../components/Admin/Layout/AdminSideBar.jsx";
import AllMessages from "../components/Admin/AllMessages.jsx";
const AdminDashboardMessages = () => {
 
    return (
        <div>
          <AdminHeader />
          <div className="flex">
            <div className="w-[70px] 800px:w-[280px]">
              <AdminSideBar active={5} />
            </div>
            <div className="flex-grow">
              <AllMessages />
            </div>
          </div>
        </div>
      );
 
}

export default AdminDashboardMessages