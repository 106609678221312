import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { server } from "../../server";

const AdminDashboardMain = () => {
  const [completedOrdersCount, setCompletedOrdersCount] = useState(0);
  const [ongoingOrdersCount, setOngoingOrdersCount] = useState(0);
  const [allVendorsCount, setAllVendorsCount] = useState(0);
  const [adminCount, setAdminCount] = useState(0);
  const [userCount, setUserCount] = useState(0);

  useEffect(() => {
    fetchAllOrdersCount();
    fetchAllVendorsCount();
    fetchAllUsersCount();
  }, []); // Only run on initial render

  const fetchAllOrdersCount = async () => {
    try {
      const response = await axios.get(`${server}/quote3D/orders/countAll`, { withCredentials: true });
      const { completedOrdersCount, ongoingOrdersCount } = response.data;

      setCompletedOrdersCount(completedOrdersCount);
      setOngoingOrdersCount(ongoingOrdersCount);
    } catch (error) {
      console.error("Error fetching orders count:", error);
    }
  };

  const fetchAllVendorsCount = async () => {
    try {
      const response = await axios.get(`${server}/shop/admin-all-sellers`, { withCredentials: true });
      const { sellers } = response.data;

      setAllVendorsCount(sellers.length);
    } catch (error) {
      console.error("Error fetching vendors count:", error);
    }
  };

  const fetchAllUsersCount = async () => {
    try {
      const response = await axios.get(`${server}/user/admin-count-users`, { withCredentials: true });
      const { adminCount, userCount } = response.data;

      setAdminCount(adminCount);
      setUserCount(userCount);
    } catch (error) {
      console.error("Error fetching users count:", error);
    }
  };

  return (
    <div className="w-full p-4">
      <div className="p-6 bg-gray-900 rounded-lg shadow-lg">
        <h3 className="text-3xl font-semibold text-gray-200 mb-6">Overview</h3>
        <div className="grid grid-cols-2 gap-6">
          <div className="bg-blue-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold mb-2">All Users</p>
              <p className="text-3xl font-bold text-blue-300">{userCount}</p>
            </div>
            <Link to="/admin-users" className="text-sm text-gray-400 flex items-center space-x-1">
              <span>View</span>
            </Link>
          </div>
          
          <div className="bg-blue-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold mb-2">All Vendors</p>
              <p className="text-3xl font-bold text-blue-300">{allVendorsCount}</p>
            </div>
            <Link to="/admin-sellers" className="text-sm text-gray-400 flex items-center space-x-1">
              <span>View</span>
            </Link>
          </div>
          <div className="bg-green-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold mb-2">Completed Orders</p>
              <p className="text-3xl font-bold text-green-300">{completedOrdersCount}</p>
            </div>
            <Link to="/admin-orders" className="text-sm text-gray-400 flex items-center space-x-1">
              <span>View</span>
            </Link>
          </div>
          <div className="bg-green-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold mb-2">Ongoing Orders</p>
              <p className="text-3xl font-bold text-green-300">{ongoingOrdersCount}</p>
            </div>
            <Link to="/admin-messages" className="text-sm text-gray-400 flex items-center space-x-1">
              <span>View</span>
            </Link>
          </div>
          <div className="bg-gray-800 p-4 rounded-lg shadow-md text-white max-w-md flex justify-between items-center">
            <div>
              <p className="text-lg font-semibold mb-2">Admins Count</p>
              <p className="text-3xl font-bold text-gray-400">{adminCount}</p>
            </div>
            {/* <Link to="/admin-users" className="text-sm text-gray-400 flex items-center space-x-1">
              <span>View</span>
            </Link> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboardMain;
