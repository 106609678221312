import React, { useEffect } from 'react';
import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { HiViewGrid } from "react-icons/hi";
import { HiOutlineBuildingStorefront } from "react-icons/hi2";
import { MdOutlineLocalPhone } from "react-icons/md";


const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col items-center justify-center h-full">
      <div className="max-w-md w-full p-8 bg-white shadow-lg rounded-lg">
        <h1 className="text-3xl font-bold mb-6 text-center border-b-2 border-gray-300 pb-4 text-gray-800">
          Contact Us
        </h1>
        <div className="flex flex-col items-center mb-4">
          <div className="flex items-center mb-4">
            <HiViewGrid className="text-2xl text-gray-600 mr-2" />
            <h2 className="text-xl font-semibold text-gray-800 mb-0 ml-2">
              LARM MAKERSGLOBE LLP
            </h2>
          </div>
          <div className="flex items-center mb-4">
            <AiOutlineMail className="text-2xl text-gray-600 mr-2" />
            <a
              href="mailto:maker.bharatt@gmail.com"
              className="text-lg text-gray-800 hover:underline"
            >
              maker.bharatt@gmail.com
            </a>
          </div>
          <div className="flex items-center mb-4">
            <HiOutlineBuildingStorefront className="text-2xl text-gray-600 mr-2" />
            <a
              className="text-lg text-gray-800 hover:underline"
            >
              GSTIN: 27AALFL5959N1ZD
            </a>
          </div>
          <div className="flex items-center">
            <MdOutlineLocalPhone className="text-2xl text-gray-600 mr-4"/>
            <p className="text-lg text-gray-800">86558 64289, 99877 99122</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
