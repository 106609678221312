import React from 'react';
import Service from '../components/Layout/Service.jsx';
import Footer from '../components/Layout/Footer.jsx';
import Header from '../components/Layout/Header.jsx';
import Loader from "../components/Layout/Loader.jsx";
import { useSelector } from "react-redux";

const ServicePage = () => {
    const { loading } = useSelector((state) => state.user);
    return (
        <div className='flex flex-col min-h-screen'>
            <Header />
            <div className="flex-1">
                <div className="container mx-auto py-8">
                    {loading ? <Loader /> : <Service />}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default ServicePage;