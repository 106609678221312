import React from "react";
// import { AiOutlineFolderAdd, AiOutlineGift } from "react-icons/ai";
import {
  // FiPackage,
  FiShoppingBag,
} from "react-icons/fi";
// import { MdOutlineLocalOffer } from "react-icons/md";
import { BsShop } from "react-icons/bs";
import { RxDashboard } from "react-icons/rx";
import { HiOutlineUserGroup } from "react-icons/hi";
// import { VscNewFile } from "react-icons/vsc";
// import {  CiSettings } from "react-icons/ci";
import { AiOutlineTags } from "react-icons/ai";
// import { MdOutlineContentCopy } from "react-icons/md";
import { Link } from "react-router-dom";
import { BiMessageSquareDetail } from "react-icons/bi";
// import { HiOutlineReceiptRefund } from "react-icons/hi";

const AdminSideBar = ({ active }) => {
  return (
    <div className="w-full h-[90vh] bg-black shadow-sm overflow-y-scroll sticky top-0 left-0 z-10">
      {/* single item */}
      <div className="w-full flex items-center p-4">
        <Link to="/admin/dashboard" className="w-full flex items-center">
          <RxDashboard
            size={30}
            color={`${active === 1 ? "#3eb7eb" : "#FFF"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 1 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            Dashboard
          </h5>
        </Link>
      </div>

      {/* <div className="w-full flex items-center p-4">
        <Link to="/dashboard-products" className="w-full flex items-center">
          <FiPackage size={30} color={`${active === 3 ? "#3eb7eb" : "#FFF"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            All Products
          </h5>
        </Link>
      </div> */}

      <div className="w-full flex items-center p-4">
        <Link to="/admin-users" className="w-full flex items-center">
          <HiOutlineUserGroup
            size={30}
            color={`${active === 2 ? "#3eb7eb" : "#FFF"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 2 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            All Users
          </h5>
        </Link>
      </div>
      <div className="w-full flex items-center p-4">
        <Link to="/admin-sellers" className="w-full flex items-center">
          <BsShop size={30} color={`${active === 3 ? "#3eb7eb" : "#FFF"}`} />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 3 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            All Vendors
          </h5>
        </Link>
      </div>
      <div className="w-full flex items-center p-4">
        <Link to="/admin-orders" className="w-full flex items-center">
          <FiShoppingBag
            size={30}
            color={`${active === 4 ? "#3eb7eb" : "#FFF"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 4 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            All Orders
          </h5>
        </Link>
      </div>

      <div className="w-full flex items-center p-4">
        <Link to="/admin-messages" className="w-full flex items-center">
          <BiMessageSquareDetail
            size={30}
            color={`${active === 5 ? "#3eb7eb" : "#FFF"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 5 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            Ongoing Orders
          </h5>
        </Link>
      </div><div className="w-full flex items-center p-4">
        <Link to="/admin-blogs" className="w-full flex items-center">
        <AiOutlineTags
            size={30}
            color={`${active === 6 ? "#3eb7eb" : "#FFF"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            Add Blogs
          </h5>
        </Link>
      </div>


      {/* <div className="w-full flex items-center p-4">
        <Link to="/profile" className="w-full flex items-center">
          <CiSettings
            size={30}
            color={`${active === 6 ? "#3eb7eb" : "#FFF"}`}
          />
          <h5
            className={`hidden 800px:block pl-2 text-[18px] font-[400] ${
              active === 6 ? "text-[#3eb7eb]" : "text-[#FFF]"
            }`}
          >
            Update Profile
          </h5>
        </Link>
      </div> */}
    </div>
  );
};

export default AdminSideBar;
