import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button } from "@mui/material";
import { AiOutlineDelete } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";

const AllMessages = () => {
  const [orders, setOrders] = useState([]);
  const [open, setOpen] = useState(false);
  const [orderId, setOrderId] = useState("");

  useEffect(() => {
    fetchCompletedOrders();
  }, []);

  const fetchCompletedOrders = async () => {
    try {
      const response = await axios.get(`${server}/quote3D/orders/ongoingOrders`, {
        withCredentials: true,
      });
      setOrders(response.data.completedOrders);
    } catch (error) {
      console.error("Error fetching completed orders:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${server}/quote3D/orders/deleteOrder/${id}`, {
        withCredentials: true,
      });
      toast.success("Order deleted successfully!");
      setOrders(orders.filter(order => order.id !== id)); // Remove deleted order from state
      setOpen(false); // Close the confirmation popup
    } catch (error) {
      console.error("Error deleting order:", error);
    }
  };

  const columns = [
    { field: "order_id", headerName: "Order ID", minWidth: 150, flex: 0.7 },
    { field: "orderName", headerName: "Order Name", minWidth: 150, flex: 0.7 },
    { field: "fullName", headerName: "Customer Name", minWidth: 150, flex: 0.7 },
    { field: "email", headerName: "Customer Email", minWidth: 150, flex: 0.7 },
    { field: "recieve", headerName: "Quotations Recieved", minWidth: 150, flex: 0.7, align: 'center' },
    { field: "items", headerName: "Items Ordered", minWidth: 150, flex: 0.7, align: 'center' },
    {
      field: "delete",
      headerName: "Delete Order",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => setOrderId(params.id) || setOpen(true)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  // Ensure orders is defined before mapping it to rows
  const rows = orders ? orders.map((order) => ({
    id: order.id,
    order_id: order.order_id,
    orderName:order.orderName,
    fullName:order.address.fullName,
    email:order.address.email,
    items:order.quotation.length,
    recieve:order.prices.length,
  })) : [];

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2 text-white ml-8">Ongoing Orders</h3>
        <div className="pl-8 pt-1">
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            className="data-grid-text-white"
          />
        </div>
        {open && (
          <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
              <div className="flex justify-end pt-2 pr-2">
                <RxCross1
                  size={25}
                  className="text-gray-500 hover:text-gray-700 cursor-pointer"
                  onClick={() => setOpen(false)}
                />
              </div>
              <div className="p-6">
                <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                  Confirm Delete Order
                </h3>
                <div className="text-gray-700 mb-4 text-center">
                  Are you sure you want to delete this order?
                </div>
                <div className="flex justify-center space-x-4">
                  <Button
                    variant="contained"
                    className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-md focus:outline-none"
                    onClick={() => setOpen(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md focus:outline-none"
                    onClick={() => handleDelete(orderId)}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AllMessages;
