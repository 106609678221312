import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Button, TextField } from "@mui/material";
import { AiOutlineDelete, AiOutlineEdit, AiOutlinePlus } from "react-icons/ai";
import { RxCross1 } from "react-icons/rx";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles for ReactQuill

const AdminBlog = () => {
  const [blogs, setBlogs] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [blogIdToDelete, setBlogIdToDelete] = useState("");
  const [openAddEditModal, setOpenAddEditModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentBlogId, setCurrentBlogId] = useState(null);
  const [newBlog, setNewBlog] = useState({
    title: "",
    content: "",
    author: "",
    image: null, // Add image to state
  });

  useEffect(() => {
    getAllBlogs();
  }, []);

  const getAllBlogs = async () => {
    try {
      const response = await axios.get(`${server}/blog/admin/all-blogs`, {
        withCredentials: true,
      });
      setBlogs(response.data.blogs);
    } catch (error) {
      console.error("Error fetching blogs:", error);
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`${server}/blog/admin/delete-blog/${id}`, {
        withCredentials: true,
      });
      toast.success("Blog deleted successfully!");
      getAllBlogs(); // Refresh blogs after deletion
      setOpenDeleteModal(false); // Close the confirmation modal after deletion
    } catch (error) {
      console.error("Error deleting blog:", error);
    }
  };

  const handleAddEdit = async () => {
    const formData = new FormData();
    formData.append("title", newBlog.title);
    formData.append("content", newBlog.content);
    formData.append("author", newBlog.author);
    if (newBlog.image) {
      formData.append("image", newBlog.image);
    }

    try {
      if (editMode) {
        await axios.put(`${server}/blog/admin/edit-blog/${currentBlogId}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        });
        toast.success("Blog updated successfully!");
      } else {
        await axios.post(`${server}/blog/admin/add-blog`, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          withCredentials: true,
        });
        toast.success("Blog added successfully!");
      }
      getAllBlogs(); // Refresh blogs after addition or edit
      setOpenAddEditModal(false); // Close the modal after addition or edit
      setNewBlog({ title: "", content: "", author: "", image: null }); // Clear the form fields
      setEditMode(false); // Reset edit mode
      setCurrentBlogId(null); // Reset current blog ID
    } catch (error) {
      console.error("Error adding or updating blog:", error);
    }
  };

  const handleEditClick = (blog) => {
    setNewBlog({
      title: blog.title,
      content: blog.content,
      author: blog.author,
      image: null, // Reset image field
    });
    setCurrentBlogId(blog.id);
    setEditMode(true);
    setOpenAddEditModal(true);
  };

  const handleAddClick = () => {
    setNewBlog({ title: "", content: "", author: "", image: null }); // Clear the form fields
    setEditMode(false);
    setOpenAddEditModal(true);
  };

  const handleFileChange = (e) => {
    setNewBlog({ ...newBlog, image: e.target.files[0] });
  };

  const columns = [
    { field: "title", headerName: "Title", minWidth: 150, flex: 0.7 },
    { field: "author", headerName: "Author", minWidth: 130, flex: 0.7 },
    { field: "createdAt", headerName: "Created At", minWidth: 130, flex: 0.8 },
    {
      field: "edit",
      headerName: "Edit Blog",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => handleEditClick(params.row)}>
          <AiOutlineEdit size={20} />
        </Button>
      ),
    },
    {
      field: "delete",
      headerName: "Delete Blog",
      flex: 1,
      minWidth: 150,
      sortable: false,
      renderCell: (params) => (
        <Button onClick={() => setBlogIdToDelete(params.id) || setOpenDeleteModal(true)}>
          <AiOutlineDelete size={20} />
        </Button>
      ),
    },
  ];

  const rows = blogs.map((blog) => ({
    id: blog.id,
    title: blog.title,
    author: blog.author,
    content: blog.content, // Include content for editing
    createdAt: new Date(blog.createdAt).toLocaleDateString(),
  }));

  return (
    <div className="w-full flex justify-center pt-5">
      <div className="w-[97%]">
        <h3 className="text-[22px] font-Poppins pb-2 text-white ml-8">
          All Blogs
        </h3>
        <div className="pl-8 pt-1">
          <Button
            className="bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md mb-4"
            startIcon={<AiOutlinePlus />}
            onClick={handleAddClick}
          >
            Add Blog
          </Button>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={10}
            disableSelectionOnClick
            autoHeight
            className="data-grid-text-white"
          />
          {openDeleteModal && (
            <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-md">
                <div className="flex justify-end pt-2 pr-2">
                  <RxCross1
                    size={25}
                    className="text-gray-500 hover:text-gray-700 cursor-pointer"
                    onClick={() => setOpenDeleteModal(false)}
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                    Confirm Delete Blog
                  </h3>
                  <div className="text-gray-700 mb-4 text-center">
                    Are you sure you want to delete this blog?
                  </div>
                  <div className="flex justify-center space-x-4">
                    <Button
                      variant="contained"
                      className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-md focus:outline-none"
                      onClick={() => setOpenDeleteModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md focus:outline-none"
                      onClick={() => handleDelete(blogIdToDelete)}
                    >
                      Confirm
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}

          {openAddEditModal && (
            <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded-lg shadow-lg w-full max-w-md max-h-full overflow-y-auto"> {/* Ensure modal is scrollable */}
                <div className="flex justify-end pt-2 pr-2">
                  <RxCross1
                    size={25}
                    className="text-gray-500 hover:text-gray-700 cursor-pointer"
                    onClick={() => setOpenAddEditModal(false)}
                  />
                </div>
                <div className="p-6">
                  <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                    {editMode ? "Edit Blog" : "Add New Blog"}
                  </h3>
                  <div className="mb-4">
                    <TextField
                      label="Title"
                      variant="outlined"
                      fullWidth
                      value={newBlog.title}
                      onChange={(e) => setNewBlog({ ...newBlog, title: e.target.value })}
                    />
                  </div>
                  <div className="mb-4 text-black">
                    <ReactQuill
                      value={newBlog.content}
                      onChange={(content) => setNewBlog({ ...newBlog, content })}
                      modules={{
                        toolbar: [
                          [{ header: [1, 2, false] }],
                          ['bold', 'italic', 'underline'],
                          ['link', 'image'],
                          ['clean'] // Remove bullet points
                        ],
                      }}
                    />
                  </div>
                  <div className="mb-4">
                    <TextField
                      label="Author"
                      variant="outlined"
                      fullWidth
                      value={newBlog.author}
                      onChange={(e) => setNewBlog({ ...newBlog, author: e.target.value })}
                    />
                  </div>
                  <div className="mb-4 text-black">
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleFileChange}
                      className="my-3"
                    />
                  </div>
                  <div className="flex justify-center space-x-4">
                    <Button
                      variant="contained"
                      className="bg-red-500 hover:bg-red-600 text-white px-6 py-2 rounded-md focus:outline-none"
                      onClick={() => setOpenAddEditModal(false)}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      className="bg-green-500 hover:bg-green-600 text-white px-6 py-2 rounded-md focus:outline-none"
                      onClick={handleAddEdit}
                    >
                      {editMode ? "Update" : "Add"}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminBlog;
